import { useEffect } from 'react';
import { useToast } from '../providers/ToastProvider';
export const useErrorToastEffect = (error, message) => {
    const { addToast } = useToast();
    useEffect(() => {
        if (error && addToast) {
            addToast({
                message,
                type: 'error',
            });
        }
    }, [error]);
};

import React, { useEffect, useRef, useState } from 'react';
import { CloseIcon, Container, Description, Input, Placeholder, RightIcon, StyledInput } from './Components';
import InputError from './InputError';
import { ArrowDown, Plus } from '#Images';
import { ArrowContainer } from '../Dropdown';
import styled from 'styled-components';
const StyledArrowContainer = styled(ArrowContainer) `
  flex-direction: column;
  gap: 8px;
  transform: translateY(-50%);

  img:first-child {
    transform: rotate(180deg) translateY(-50%);
  }
`;
const FormInput = ({ onChange, onClear, name, value, type = 'text', description, errorText, ref, ariaLabel, bottomMargin = 0, iconUrl, rightIconUrl, disabled, onFocus, onBlur, readonly, comboArrows, }) => {
    const [active, setActive] = useState(false);
    const inputRef = useRef(null);
    const slide = () => {
        if (!value) {
            setActive(!active);
        }
    };
    useEffect(() => {
        if (value)
            setActive(true);
    }, [value]);
    const onContainerClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    const onInputFocus = () => {
        slide();
        onFocus && onFocus();
    };
    const onInputBlur = () => {
        slide();
        onBlur && onBlur();
    };
    return (React.createElement(StyledInput, { "$bottomMargin": bottomMargin },
        React.createElement(Container, { disabled: disabled, active: active, error: !!errorText, onClick: onContainerClick },
            iconUrl && React.createElement("img", { style: { width: 20, height: 20 }, className: "input-icon", src: iconUrl }),
            React.createElement(Placeholder, { style: { marginLeft: iconUrl && !active ? 27 : 0 }, active: active }, name),
            React.createElement(Input, { readOnly: readonly, autoComplete: 'off', style: { marginLeft: iconUrl ? 27 : 0 }, isFilled: !!value, "aria-label": ariaLabel, id: "form-input-new", ref: ref || inputRef, onBlur: onInputBlur, onFocus: onInputFocus, value: value, onChange: e => onChange(e.target.value), step: type === 'number' ? 0.1 : undefined, type: type, disabled: disabled }),
            onClear && value && React.createElement(CloseIcon, { onClick: onClear, src: Plus }),
            rightIconUrl && !onClear && React.createElement(RightIcon, { src: rightIconUrl, alt: "icon" }),
            comboArrows && (React.createElement(StyledArrowContainer, { "$opened": false },
                React.createElement("img", { src: ArrowDown, alt: "arrow down", width: 10, height: 6 }),
                React.createElement("img", { src: ArrowDown, alt: "arrow down", width: 10, height: 6 })))),
        !!description && !errorText && React.createElement(Description, null, description),
        !!errorText && React.createElement(InputError, null, errorText)));
};
export default FormInput;

import { TextArea } from '#Components/CommonStyled';
import { Button, FormInput } from '#Components/shared';
import React from 'react';
import { ColumnFlexContainer } from '../shared/total-sheet/components';
import { BottomButtonsWrapper } from '../shared/components';
import { useErrorToastEffect, useStores } from '#Hooks';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
const Details = () => {
    const { clientId } = useParams();
    const navigate = useNavigate();
    const { storageReturnData: { setContactData, contactData, createOrder }, appData: { process, error }, } = useStores();
    const onConfirmClick = async () => {
        const hubSpotId = await createOrder(Number(clientId));
        if (hubSpotId) {
            navigate(`/clients/${clientId}/return/${hubSpotId}`);
        }
    };
    useErrorToastEffect(error, 'Error with order creating');
    return (React.createElement(React.Fragment, null,
        React.createElement(ColumnFlexContainer, null,
            React.createElement(FormInput, { errorText: '', name: 'Email', onChange: v => setContactData({ ...contactData, email: v }), value: contactData.email }),
            React.createElement(FormInput, { errorText: '', name: 'Phone', onChange: v => setContactData({ ...contactData, phone: v }), value: contactData.phone }),
            React.createElement(TextArea, { value: contactData.comment, placeholder: "Comments", onChange: e => setContactData({ ...contactData, comment: e.target.value }), rows: 6 })),
        React.createElement(BottomButtonsWrapper, null,
            React.createElement(Button, { onClick: onConfirmClick, full: true, kind: 'primary', loading: process, type: "button" }, "Confirm"))));
};
export default observer(Details);

import styled, { css } from 'styled-components';
export const StyledButton = styled.button `
  width: ${props => (props.full ? '100%' : 'auto')};
  max-width: ${props => (props.max ? '232px' : 'initial')};
  font-family: 'TT Travels';
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  border: none;
  padding: 0;
  margin: 0;
  padding: 12px 28px;
  font-family: 'TT Travels';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  cursor: pointer;

  box-shadow: 0px 16px 24px rgba(116, 34, 15, 0.1);

  :disabled {
    background-color: var(--main-gray-dark);
    cursor: not-allowed;
  }

  ${({ kind, disabled, loading }) => {
    if (kind === 'primary' && !loading) {
        return css `
        background-color: var(--main-color);
        color: #ffffff;
      `;
    }
    else if (disabled) {
        return css `
        background-color: var(--main-gray-dark);
        color: grey;
        cursor: not-allowed;
      `;
    }
    else {
        return css `
        background-color: var(--main-gray);
        color: var(--main-black);
      `;
    }
}}

  img {
    width: 32px;
    height: 32px;
    position: absolute;
    left: 32px;
    top: calc(50% - 16px);
  }
`;
export const ButtonText = styled.span `
  visibility: ${props => (props.loading ? 'hidden' : 'initial')};
`;
export const Spinner = styled.svg `
  position: absolute;

  left: ${props => `${props.noLeftMargin ? 'initial' : 'calc(50% - 21px)'}`};
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};
  margin-right: 8px;

  & .path {
    stroke: var(--main-color);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

import 'react-datepicker/dist/react-datepicker.css';
import './datePicker.scss';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
const DatePicker = ({ value, onChange }) => (React.createElement(ReactDatePicker, { disabledKeyboardNavigation: true, renderCustomHeader: ({ decreaseMonth, increaseMonth, date, prevMonthButtonDisabled, nextMonthButtonDisabled }) => {
        const curMonth = new Date(date).getMonth();
        const curYear = new Date(date).getFullYear();
        return (React.createElement("div", { className: "datepicker-header" },
            React.createElement("button", { "aria-label": "btn-left", type: "button", className: "month-changer month-changer_left", onClick: decreaseMonth, disabled: prevMonthButtonDisabled }),
            React.createElement("div", { className: "datepicker-header__month" },
                months[curMonth],
                " ",
                React.createElement("span", { className: "datepicker-header__year" }, curYear)),
            React.createElement("button", { "aria-label": "btn-right", type: "button", className: "month-changer month-changer_right", onClick: increaseMonth, disabled: nextMonthButtonDisabled })));
    }, 
    // temp fix for no default value
    selected: value || '', className: "datepicker-calendar", inline: true, calendarClassName: "datepicker-body", dayClassName: date => 'datepicker-days', onChange: date => onChange(date), minDate: null }));
export default DatePicker;

import MobileItemPicker from '#Components/MobileItemPicker';
import { BlockWithIcon } from '#Components/shared';
import IconBtnBLock from '#Components/shared/IconBtnBLock';
import { FolderIcon, Plus } from '#Images';
import React, { useState } from 'react';
import ItemsCart from './ItemsCart';
import useStores from '#Hooks/useStores';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';
import CartItem from './CartItem';
import { getNumberFromString } from '#Utils/helpers';
const ItemsBlock = () => {
    const { spaceData } = useStores();
    const callbackRender = (f) => spaceData.allItems
        .filter(item => item.title.toLocaleLowerCase().includes(f.toLocaleLowerCase()))
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((item, index) => {
        const isSelected = spaceData.selectedItems.findIndex(i => i.title === item.title) !== -1;
        const updatedItem = { ...item, id: uuidv4(), info_id: item.id };
        const addedItemAmount = spaceData.selectedItems.find(i => i.title === item.title)?.amount || 1;
        return (React.createElement(CartItem, { inModal: true, onSelect: () => spaceData.addFurnitureItem(updatedItem), edit: true, onRemove: () => spaceData.removeFurnitureItem(item.title), key: index, isSelected: isSelected, title: item.title, amount: addedItemAmount, onChange: v => spaceData.onSetItemQty(item.title, getNumberFromString(v)) }));
    });
    const [isMobilePickerOpen, seIsMobilePickerOpen] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(BlockWithIcon, { rightBtnProps: !!spaceData.selectedItems.length
                ? {
                    title: 'Add item',
                    iconUrl: Plus,
                    onClick: () => seIsMobilePickerOpen(true),
                    disabled: !spaceData.isEdit,
                }
                : undefined, iconUrl: FolderIcon, title: "Inventory" }, !spaceData.selectedItems.length ? (React.createElement(IconBtnBLock, { disabled: !spaceData.isEdit, full: true, iconUrl: Plus, title: "Add items", onClick: () => seIsMobilePickerOpen(true) })) : (React.createElement(ItemsCart, { isEdit: spaceData.isEdit, furnitureItems: spaceData.selectedItems, setItems: spaceData.onSetItemQty }))),
        isMobilePickerOpen && (React.createElement(MobileItemPicker, { onAddNewItem: spaceData.addNewFurnitureItem, totalItems: spaceData.selectedItems.length, setIsOpen: () => seIsMobilePickerOpen(false), renderSuggestions: callbackRender }))));
};
export default observer(ItemsBlock);

import { DesignFonts } from '../style/DesignFonts';
import styled from 'styled-components';
import { CheckMarkFox } from '#Images';
export const Container = styled.div `
  max-width: 390px;
  padding: 0 16px;
  margin: 0 auto;
  position: relative;

  @media screen and (min-width: 768px) {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }
`;
export const AddItemBLock = styled.div `
  width: 114px;
  height: 114px;
  border-radius: 16px;
  border: 2px solid var(--main-light-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const StyledIconBtnBLock = styled(AddItemBLock) `
  border-width: 1px;
  width: unset;
  width: 100%;
  max-width: 173px;
  height: 90px;
  ${({ $full }) => $full && `max-width: unset;`}
  ${({ $lined }) => $lined && `border: none; height: unset; justify-content: flex-start;`};
`;
export const BlockContent = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-bottom: 4px;
  }
  ${({ $lined }) => $lined && `flex-direction: row; img {margin-right: 8px; margin-bottom: 0;}`};
`;
export const SpaceItem = styled.span `
  margin-right: auto;
  ${DesignFonts.smallPrint}
`;
export const TextArea = styled.textarea `
  transition: all 0.3s ease-in-out;
  border: 1px solid var(--main-light-gray);
  border-radius: 8px;
  outline: none;
  padding: 8px 16px;
  font-family: 'TT Travels';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  box-sizing: border-box;
  resize: none;

  &:focus,
  :hover {
    border-color: var(--main-black);
  }

  &:disabled {
    background-color: var(--main-light-gray);
    :hover {
      border-color: var(--main-light-gray);
    }
  }
`;
export const UnitBlockImage = styled.img `
  position: absolute;
  bottom: 0;
  right: 0;
`;
export const BlockTitle = styled.h5 `
  ${DesignFonts.headlineH5}
  margin-bottom: 8px;
`;
export const SearchListItem = styled.div `
  cursor: pointer;
  position: relative;
  padding: 8px 0;
  border-bottom: 1px solid var(--main-light-gray);
  ${DesignFonts.bodyDemiBold}
  p {
    ${DesignFonts.bodyRegular}
  }
  ${({ $selected }) => $selected &&
    `
  :after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    top: calc(50% - 12px);
    background: url(${CheckMarkFox});

  }
  `}
`;
export const MobileModalContainer = styled.div `
  /* height: calc(100 * var(--vh, 1vh)); */
  display: flex;
  /* width: 100%; */
  flex-direction: column;
  z-index: 9999;
  background: white;
  /* position: absolute;
  top: 0; */

  overflow: hidden;
  position: fixed;
  top: 0;
  max-width: 390px;
  width: 100%;
  bottom: 0;
  padding: 0 16px;
`;
export const HeaderButtons = styled.div `
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
export const HeaderButton = styled.button `
  background: none;
  border: none;
  cursor: pointer;

  ${DesignFonts.bodyDemiBold}
  :disabled {
    color: var(--main-gray);
  }

  ${props => props.$active &&
    `
        color: var(--main-color);
    `}
`;

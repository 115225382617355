import React, { useState } from 'react';
import dayjs from 'dayjs';
import { getTimeSlotFromDateString } from '#Utils/helpers';
import { ColumnFlexContainer, InfoRowLine } from '#Components/StorageReturn/shared/total-sheet/components';
import { Services } from '#Types/storageReturnDTO';
import styled from 'styled-components';
import { ArrowDown } from '#Images';
const ItemContainer = styled.div `
  padding: 18px 12px;
  background-color: var(--main-light-gray);
  border-radius: 8px;
  cursor: pointer;

  ${ColumnFlexContainer} {
    transition: max-height 0.4s ease, opacity 0.4s ease;
    opacity: 0;
    max-height: 0;
    overflow: hidden;

    ${({ $opened }) => $opened &&
    `
      opacity: 1;
      max-height: 1000px;
    `}
  }
`;
const CollectionReturnItem = ({ data }) => {
    const [opened, setOpened] = useState(false);
    const isCollection = data.services.includes(Services.STORAGE_COLLECTION);
    const prices = data.amounts_paid[isCollection ? Services.STORAGE_COLLECTION : Services.STORAGE_RETURN];
    return (React.createElement(ItemContainer, { onClick: () => setOpened(!opened), "$opened": opened },
        React.createElement("span", { style: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' } },
            isCollection ? 'Collection' : 'Return',
            ' ',
            dayjs(data?.order_date).format('DD-MM-YYYY') +
                ' | ' +
                `${getTimeSlotFromDateString(data?.time_start || '00')}:00-${getTimeSlotFromDateString(data?.time_end || '00')}:00`,
            React.createElement("img", { src: ArrowDown, style: { width: 9, height: 10, marginLeft: 10, transform: opened ? 'rotate(-180deg)' : '' }, alt: "collection" })),
        React.createElement("div", { style: { padding: '0 12px' } },
            React.createElement(ColumnFlexContainer, null,
                React.createElement(InfoRowLine, { style: { marginTop: 14 } },
                    React.createElement("span", null,
                        "Order: ",
                        data?.hubspot_order_id,
                        " ")),
                React.createElement(InfoRowLine, null,
                    React.createElement("span", null,
                        "\uD83D\uDDD3\uFE0F Date and time:",
                        ' ',
                        dayjs(data?.order_date).format('DD MMMM YYYY') +
                            ' | ' +
                            `${getTimeSlotFromDateString(data?.time_start || '00')}:00-${getTimeSlotFromDateString(data?.time_end || '00')}:00`)),
                React.createElement(InfoRowLine, null,
                    React.createElement("span", null,
                        "\uD83D\uDCAA People required: ",
                        data.amount_of_movers)),
                React.createElement(InfoRowLine, null,
                    React.createElement("span", null,
                        "\uD83D\uDE9A Vans required: ",
                        data.amount_of_vehicles)),
                React.createElement(InfoRowLine, null,
                    React.createElement("span", null,
                        "\uD83D\uDCCF Moving in duration: ",
                        data.moving_in_length_str)),
                React.createElement(InfoRowLine, null,
                    React.createElement("span", null,
                        "\uD83D\uDCCF Moving out duration: ",
                        data.moving_out_length_str)),
                React.createElement(InfoRowLine, null,
                    React.createElement("span", null,
                        "\uD83D\uDCCF En route duration: ",
                        data.en_route_length_str)),
                React.createElement(InfoRowLine, null,
                    React.createElement("span", null,
                        "\uD83D\uDCCF Client duration:",
                        data.client_length_str)),
                React.createElement(InfoRowLine, null,
                    React.createElement("span", null,
                        "\uD83D\uDCDC Items:",
                        ' ',
                        data?.items.map((item, index) => (React.createElement("span", { key: index },
                            item.title,
                            " - ",
                            item.amount,
                            data?.items.length - 1 === index ? '' : ', '))))),
                React.createElement(InfoRowLine, { "$bold": true },
                    React.createElement("span", null,
                        "\uD83D\uDCB0\uFE0F Collection price without extracharge: \u00A3",
                        prices.amount_without_extra_charge)),
                React.createElement(InfoRowLine, { "$bold": true },
                    React.createElement("span", null,
                        "\u203C\uFE0F Extracharge: \u00A3",
                        prices.extra_charge)),
                React.createElement(InfoRowLine, null, isCollection ? (React.createElement("span", null,
                    "\uD83C\uDFE0 Collection floor: ",
                    data?.collection_floor,
                    " Lift: ",
                    data?.collection_elevator ? 'Yes' : 'No')) : (React.createElement("span", null,
                    "\uD83C\uDFE0 Dropoff floor: ",
                    data?.dropoff_floor,
                    " Lift: ",
                    data?.dropoff_elevator ? 'Yes' : 'No'))),
                React.createElement(InfoRowLine, null,
                    React.createElement("span", null,
                        "\uD83D\uDCCD Collection address: ",
                        data?.collection_address)),
                React.createElement(InfoRowLine, null,
                    React.createElement("span", null,
                        "\uD83D\uDCCD Delivery address: ",
                        data?.dropoff_address)),
                React.createElement(InfoRowLine, null,
                    React.createElement("span", null,
                        "\u2757 Comment: ",
                        data.comment || 'No comment'))))));
};
export default CollectionReturnItem;

import React, { useState } from 'react';
import SwipeContainer from '../SwipeContainer';
import MobileDatePicker from './MobileDatePicker';
import { FormInput } from '#Components/shared';
import dayjs from 'dayjs';
const DatePickerBlock = ({ date, onSelectDate, title, disabled }) => {
    const [dateSwiperOpen, setDateSwiperOpen] = useState(false);
    const onOpenContainer = () => {
        if (disabled) {
            return;
        }
        setDateSwiperOpen(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FormInput, { disabled: disabled, readonly: true, onFocus: onOpenContainer, name: title, onChange: () => { }, value: date ? dayjs(date).format('DD-MM-YYYY') : '', bottomMargin: 12 }),
        React.createElement(SwipeContainer, { onClose: () => setDateSwiperOpen(false), needCloseBtn: true, opened: dateSwiperOpen },
            React.createElement(MobileDatePicker, { onChange: d => {
                    onSelectDate(d || new Date());
                    setDateSwiperOpen(false);
                }, value: date }))));
};
export default DatePickerBlock;

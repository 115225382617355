import React from 'react';
import { DesignFonts } from '../../style/DesignFonts';
import styled from 'styled-components';
const BlockContainer = styled.div `
  margin-bottom: 24px;
  width: 100%;
`;
const BlockHeader = styled.div `
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TitleWithIcon = styled.div `
  ${DesignFonts.headlineH5}
  display: flex;
  align-items: center;

  img {
    margin-right: 4px;
  }
`;
const RightBtn = styled.button `
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  ${DesignFonts.bodyDemiBold}
  flex-shrink: 0;

  img {
    margin-right: 8px;
    width: 16px;
  }
  :disabled {
    opacity: 0.5;
    cursor: default;
  }
`;
const BLockWithIcon = ({ iconUrl, title, children, rightBtnProps }) => {
    return (React.createElement(BlockContainer, null,
        React.createElement(BlockHeader, null,
            React.createElement(TitleWithIcon, null,
                iconUrl && React.createElement("img", { src: iconUrl, alt: "icon" }),
                React.createElement("span", null, title)),
            rightBtnProps && (React.createElement(RightBtn, { disabled: rightBtnProps.disabled, onClick: rightBtnProps.onClick },
                React.createElement("img", { src: rightBtnProps.iconUrl, alt: "icon" }),
                rightBtnProps.title))),
        children));
};
export default BLockWithIcon;

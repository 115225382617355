import { makeAutoObservable, runInAction } from 'mobx';
import ApiService from '#Services/apiService';
const api = new ApiService();
import { v4 as uuidv4 } from 'uuid';
class AppData {
    loading = false;
    process = false;
    storagePlans = [];
    error = null;
    success = false;
    constructor() {
        makeAutoObservable(this);
    }
    setError = (error) => {
        this.error = error;
    };
    setProcess = (process) => {
        this.process = process;
    };
    setLoading = (l) => {
        this.loading = l;
    };
    addNewUnit = async (storageId, data) => {
        runInAction(() => {
            this.process = true;
            this.error = null;
        });
        try {
            await api.postAddNewUnit(data, storageId);
            return true;
        }
        catch (error) {
            this.error = 'Problem with unit creating';
            return false;
        }
        finally {
            runInAction(() => {
                this.process = false;
            });
        }
    };
    fetchStoragePlans = async () => {
        try {
            runInAction(() => {
                this.process = true;
                this.error = null;
            });
            const res = await api.getAllClientPlans();
            this.storagePlans = res;
        }
        catch (error) {
            console.log(error);
        }
        finally {
            runInAction(() => {
                this.process = false;
            });
        }
    };
    updateClientInfo = async (clientId, data) => {
        try {
            runInAction(() => {
                this.process = true;
                this.error = null;
                this.success = false;
            });
            await api.patchClient(clientId, data);
            this.success = true;
        }
        catch (error) {
            console.log(error);
            this.error = 'Problem with client updating';
        }
        finally {
            runInAction(() => {
                this.process = false;
                setTimeout(() => {
                    this.success = false;
                }, 5000);
            });
        }
    };
    addNewItem = async (item) => {
        runInAction(() => {
            this.process = true;
            this.error = null;
            this.success = false;
        });
        try {
            const newItem = {
                id: uuidv4(),
                key: item.key || '',
                title: item.title,
            };
            const createdItem = await api.postAddNewItem(newItem);
            this.success = true;
            return createdItem;
        }
        catch (error) {
            this.error = 'Problem with item adding';
            return null;
        }
        finally {
            runInAction(() => {
                this.process = false;
                setTimeout(() => {
                    this.success = false;
                }, 5000);
            });
        }
    };
}
export default new AppData();

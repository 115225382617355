import { BlockWithIcon } from '#Components/shared';
import IconBtnBLock from '#Components/shared/IconBtnBLock';
import { CameraIcon, ImageIcon, Plus, TrashBin } from '#Images';
import React, { useEffect, useState } from 'react';
import StyledDropzone from './StyledDropZone';
import useStores from '#Hooks/useStores';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { BlockContainer } from '#Components/UnitBlock/UnitBlock';
import SwipeContainer from '../SwipeContainer';
import { ApiService } from '#Services';
import { MediaFileLoader } from './skeletons';
import { v4 as uuidv4 } from 'uuid';
import { useToast } from '../../../providers/ToastProvider';
import ReactPlayer from 'react-player';
const api = new ApiService();
export const MediaModal = styled.div `
  position: fixed;
  top: 0;
  max-width: 390px;
  width: 100%;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  transform: translateX(-16px);

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }

  button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    background-color: transparent;
    border: none;
    outline: none;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
`;
export const ImageContainer = styled.div `
  width: 122px;
  height: 90px;
  border: 1px solid lightgray;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(70%);
  }
`;
const SwiperBtnContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const RemoveBtn = styled.button `
  position: absolute;
  border-radius: 50%;
  top: 12px;
  right: 12px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  background: url(${TrashBin}) no-repeat center #fff;
  cursor: pointer;
  color: var(--main-black);
`;
export const AddItemBLock = styled.div `
  min-width: 90px;
  flex-shrink: 0;
  /* width: 100%; */
  height: 90px;
  border-radius: 16px;
  border: 1px solid var(--main-light-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ $disabled }) => $disabled && `opacity: 0.5; cursor: default;`};
`;
const MediaBlock = () => {
    const { addToast } = useToast();
    const [addPanelOpened, setAddPanelOpened] = useState(false);
    const [viewMedia, setViewMedia] = useState(null);
    const { spaceData, appData } = useStores();
    const files = spaceData.mediaFiles.map(f => ({ ...f, isLoading: false }));
    const [uploadingFiles, setUploadingFiles] = useState(files);
    useEffect(() => {
        if (!uploadingFiles.length) {
            setUploadingFiles(files);
        }
    }, [files.length]);
    useEffect(() => {
        const isAnyFileUploading = uploadingFiles.some(file => file.isLoading);
        if (isAnyFileUploading) {
            appData.setLoading(true);
        }
        else {
            appData.setLoading(false);
        }
        return () => {
            appData.setLoading(false);
        };
    }, [JSON.stringify(uploadingFiles)]);
    const onOpenAddPanel = () => {
        setAddPanelOpened(true);
    };
    const onRemoveFile = (id) => {
        setUploadingFiles(prev => prev.filter(f => f.id !== id));
        spaceData.removeMediaItem(id);
    };
    const handleFileChange = (f) => {
        const files = f || [];
        const fileArray = Array.from(files);
        if (files) {
            for (const file of fileArray) {
                const newFile = {
                    id: uuidv4(),
                    isLoading: true,
                    file,
                    content_type: file.type,
                    url: '',
                };
                setUploadingFiles(prev => [...prev, newFile]);
                postAddNewFile(file, newFile.id);
            }
        }
    };
    const onMediaFileClick = (image) => {
        setViewMedia(image);
        document.body.classList.add('scroll-hidden');
    };
    const onCloseViewMedia = () => {
        document.body.classList.remove('scroll-hidden');
        setViewMedia(null);
    };
    const postAddNewFile = async (file, fileId) => {
        try {
            const tetRes = await api.postAddNewFile(file);
            setUploadingFiles(prev => prev.map(f => {
                if (f.id === fileId) {
                    return { ...f, id: tetRes.id, isLoading: false, url: tetRes.url, thumbnail: tetRes.thumbnail };
                }
                return f;
            }));
            spaceData.addMediaItem(tetRes);
            setAddPanelOpened(false);
        }
        catch (error) {
            setUploadingFiles(prev => prev.filter(f => f.id !== fileId));
            addToast?.({ type: 'error', message: `Failed to upload file ${file.name}` });
            console.error(error);
        }
    };
    return (React.createElement(React.Fragment, null,
        viewMedia && (React.createElement(MediaModal, null,
            React.createElement("button", { style: { fontSize: 20, zIndex: 100 }, onClick: onCloseViewMedia }, "\u2716"),
            viewMedia.content_type.includes('image') ? (React.createElement("img", { src: viewMedia.url, alt: 'img' })) : (React.createElement(ReactPlayer, { width: '100%', height: '100%', url: viewMedia.url, controls: true })))),
        React.createElement(BlockWithIcon, { iconUrl: ImageIcon, title: "Media" }, !uploadingFiles.length ? (React.createElement(React.Fragment, null,
            React.createElement(BlockContainer, null,
                React.createElement("label", { style: { maxWidth: '173px', width: '100%' }, htmlFor: "file-loader-1" },
                    React.createElement(IconBtnBLock, { disabled: !spaceData.isEdit, iconUrl: CameraIcon, title: "Camera" },
                        React.createElement("input", { disabled: !spaceData.isEdit, id: "file-loader-1", style: { display: 'none' }, type: "file", accept: "image/*,video/*", capture: "environment", onChange: (e) => handleFileChange(e.target.files) }))),
                React.createElement(StyledDropzone, { onSetImages: handleFileChange })))) : (React.createElement(BlockContainer, { style: { flexWrap: 'nowrap', display: 'grid', gridTemplateColumns: '3fr 1fr' } },
            React.createElement(BlockContainer, null, uploadingFiles.map((image, index) => (React.createElement(React.Fragment, null, image.isLoading ? (React.createElement(MediaFileLoader, null)) : (React.createElement(ImageContainer, { key: index },
                React.createElement("img", { onClick: () => onMediaFileClick(image), src: image.content_type.includes('image') ? image.url : image.thumbnail?.url, alt: 'img' }),
                spaceData.isEdit && React.createElement(RemoveBtn, { onClick: () => onRemoveFile(image.id) }))))))),
            React.createElement(AddItemBLock, { "$disabled": !spaceData.isEdit, onClick: !spaceData.isEdit ? undefined : onOpenAddPanel },
                React.createElement("img", { src: Plus, alt: "icon" }))))),
        React.createElement(SwipeContainer, { onClose: () => setAddPanelOpened(false), needCloseBtn: true, opened: addPanelOpened },
            React.createElement(SwiperBtnContainer, null,
                React.createElement("label", { style: { maxWidth: '173px', width: '100%' }, htmlFor: "file-loader-1" },
                    React.createElement(IconBtnBLock, { lined: true, disabled: !spaceData.isEdit, iconUrl: CameraIcon, title: "Camera" },
                        React.createElement("input", { disabled: !spaceData.isEdit, id: "file-loader-1", style: { display: 'none' }, type: "file", accept: "image/*,video/*", capture: "environment", onChange: (e) => handleFileChange(e.target.files) }))),
                React.createElement(StyledDropzone, { lined: true, onSetImages: handleFileChange })))));
};
export default observer(MediaBlock);

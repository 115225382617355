import { MobileModalContainer, SearchListItem } from '#Components/CommonStyled';
import Header from '#Components/Header/Header';
import React from 'react';
const ListModal = ({ title, list, onItemClick, onClose, selectedItem }) => {
    return (React.createElement(MobileModalContainer, null,
        React.createElement(Header, { onBackBtnClick: onClose, onClose: onClose, title: title }),
        list.map(item => (React.createElement(SearchListItem, { "$selected": item.value === selectedItem, key: item.key, onClick: () => onItemClick(item.key) },
            React.createElement("p", null, item.value))))));
};
export default ListModal;

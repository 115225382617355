import React from 'react';
import { DesignFonts } from '../../style/DesignFonts';
import styled from 'styled-components';
import useOnclickOutside from 'react-cool-onclickoutside';
const MenuContainer = styled.div `
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  gap: 14px;
  box-shadow: 8px 8px 48px 0px rgba(0, 0, 0, 0.15);
  box-shadow: -8px -8px 48px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  align-items: flex-start;
  margin-right: 16px; // container padding
`;
const MenuItem = styled.div `
  display: flex;
  align-items: center;
  cursor: pointer;
  ${DesignFonts.bodyDemiBold}
  img {
    margin-right: 14px;
  }
  &:hover {
    color: var(--main-color);
  }
`;
const Menu = ({ items, onClose }) => {
    const ref = useOnclickOutside(() => {
        onClose();
    });
    const onItemClick = (item) => {
        onClose();
        item.onClick();
    };
    return (React.createElement(MenuContainer, { ref: ref }, items.map(item => (React.createElement(MenuItem, { onClick: () => onItemClick(item), key: item.title },
        React.createElement("img", { width: 24, src: item.iconUrl, alt: item.title }),
        React.createElement("span", null, item.title))))));
};
export default Menu;

import './scss/index.scss';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from '#Components/ErrorBoundary/ErrorBoundary';
import { ToastProvider } from './providers/ToastProvider';
import AuthProvider from './providers/AuthProvider';
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(React.createElement(React.StrictMode, null,
    React.createElement(ErrorBoundary, null,
        React.createElement(AuthProvider, null,
            React.createElement(QueryClientProvider, { client: queryClient },
                React.createElement(BrowserRouter, null,
                    React.createElement(ToastProvider, null,
                        React.createElement(App, null))))))));

import { BlockWithIcon, Button, FormInput } from '#Components/shared';
import React, { useState } from 'react';
import { ButtonKinds } from '#Components/shared/Button/Button';
import Header from '#Components/Header/Header';
import styled from 'styled-components';
const BottomBtnWrapper = styled.div `
  /* position: absolute;  */
  /* bottom: 20px;  */
  /* width: calc(100% - 32px); */
  /* margin-top: calc(100% - 52px); */
  width: 100%;
  margin-top: 36px;
`;
const CreateUnitPage = ({ title, subtitle, btnProps, onClose, onBackClick }) => {
    const [sealNumber, setSealNumber] = useState('');
    const [unitTitle, setUnitTitle] = useState('');
    const [errors, setErrors] = useState({
        unitTitle: false,
    });
    const onSetSealNumber = (value) => {
        setSealNumber(value);
    };
    const onBtnClick = () => {
        if (!unitTitle) {
            return setErrors({
                unitTitle: !unitTitle,
            });
        }
        const data = {
            seal_number: sealNumber,
            title: unitTitle,
        };
        btnProps.onClick(data);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { onBackBtnClick: onClose, title: title, subtitle: subtitle, onClose: onClose }),
        React.createElement(BlockWithIcon, { title: "Enter the order details" },
            React.createElement(FormInput, { name: 'Seal Number', description: "Change the seal number if needed", onChange: onSetSealNumber, value: sealNumber, bottomMargin: 12 }),
            React.createElement(FormInput, { errorText: errors.unitTitle ? 'Field required' : '', name: 'Unit title', onChange: setUnitTitle, value: unitTitle, bottomMargin: 12 })),
        React.createElement(BottomBtnWrapper, null,
            React.createElement(Button, { loading: btnProps.isLoading, full: true, type: "submit", onClick: onBtnClick, kind: ButtonKinds.primary }, btnProps.title))));
};
export default CreateUnitPage;

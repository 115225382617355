import { Header } from '#Components';
import CancelOrderModal from '#Components/ReturnsBlock/CancelOrderModal';
import RescheduleModal from '#Components/ReturnsBlock/RescheduleModal';
import TotalSheetDetails from '#Components/StorageReturn/slides/TotalSheetDetails';
import { useStores } from '#Hooks';
import { TrashBin } from '#Images';
import { ReturnStatus } from '#Types/storageReturnDTO';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
const ReturnInfo = () => {
    const [isCancelOrderOpen, setIsCancelOrderOpen] = useState(false);
    const [isRescheduleOrderOpen, setIsRescheduleOrderOpen] = useState(false);
    const navigate = useNavigate();
    const { clientId, returnId } = useParams();
    const { storageReturnData } = useStores();
    const onHeaderBackClick = () => {
        return navigate(`/clients/${clientId}`);
    };
    const onCancelOrderClick = () => {
        setIsCancelOrderOpen(true);
    };
    const onRescheduleClick = () => {
        setIsRescheduleOrderOpen(true);
    };
    const isCancellable = storageReturnData.currentStatus === ReturnStatus.SEND_INVOICE;
    const orderMenuItems = [
        { iconUrl: TrashBin, onClick: onCancelOrderClick, title: 'Cancel order' },
        // { iconUrl: EditIcon, onClick: onRescheduleClick, title: 'Reschedule' },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { orderMenuItems: orderMenuItems, onBackBtnClick: onHeaderBackClick, title: 'Details' }),
        React.createElement(TotalSheetDetails, { returnId: Number(returnId) }),
        isCancelOrderOpen && (React.createElement(CancelOrderModal, { open: isCancelOrderOpen, onClose: () => setIsCancelOrderOpen(false), onRemove: () => { } })),
        isRescheduleOrderOpen && (React.createElement(RescheduleModal, { open: isRescheduleOrderOpen, onClose: () => setIsRescheduleOrderOpen(false), onRemove: () => { } }))));
};
export default ReturnInfo;

import React, { useEffect, useState } from 'react';
import { HeaderButton, HeaderButtons } from '#Components/CommonStyled';
import Loader from '#Components/shared/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiService } from '#Services';
import { useToast } from '../../providers/ToastProvider';
import SwipeContainer from '#Components/AddSpace/SwipeContainer';
const api = new ApiService();
const CancelOrderModal = ({ onClose, open, onRemove }) => {
    const { addToast } = useToast();
    const [updating, setUpdating] = useState(false);
    const { returnId, clientId } = useParams();
    const navigate = useNavigate();
    const [beforeRemoveTimer, setBeforeRemoveTimer] = useState(5000);
    useEffect(() => {
        const timer = setInterval(() => {
            setBeforeRemoveTimer(t => t - 1000);
        }, 1000);
        if (beforeRemoveTimer === 0) {
            clearInterval(timer);
        }
        return () => {
            clearInterval(timer);
        };
    }, [beforeRemoveTimer]);
    const onCancel = async () => {
        try {
            setUpdating(true);
            await api.postCancelHubSpotOrder(Number(returnId));
            addToast &&
                addToast({
                    message: 'Order successfully removed',
                    type: 'success',
                });
            navigate(`/clients/${clientId}`);
        }
        catch (error) {
            addToast &&
                addToast({
                    message: 'Something went wrong',
                    type: 'error',
                });
        }
        finally {
            setUpdating(false);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SwipeContainer, { opened: open, onClose: onClose },
            React.createElement("div", { id: "remove_order", style: { display: 'flex', flexDirection: 'column', minHeight: '80vh' } },
                React.createElement(HeaderButtons, null,
                    React.createElement(HeaderButton, { onClick: onClose }, "Back"),
                    React.createElement(HeaderButton, { disabled: beforeRemoveTimer > 0, 
                        // $active={!!selectedUnitId && !transferring}
                        onClick: onCancel }, "Cancel")),
                !!beforeRemoveTimer && React.createElement("p", null,
                    "You can remove order after ",
                    beforeRemoveTimer / 1000,
                    " seconds"),
                updating && React.createElement(Loader, null)))));
};
export default CancelOrderModal;

import React, { useState } from 'react';
import Header from '#Components/Header/Header';
import { ClockIcon } from '#Images';
import { BlockContainer } from '#Components/UnitBlock/UnitBlock';
import TimeLineModal from '#Components/TimeLine/TimeLineModal';
import { getSpaceName } from '#Utils/helpers';
import ItemsBlock from '#Components/AddSpace/components/ItemsBlock';
import MediaBlock from '#Components/AddSpace/components/MediaBlock';
import CommentBlock from '#Components/AddSpace/components/CommentBlock';
const ArchivedSpace = ({ space, onClose }) => {
    const [isHistoryPageOpen, setIsHistoryPageOpen] = useState(false);
    const menuItems = [
        {
            title: 'View timeline',
            iconUrl: ClockIcon,
            onClick: () => setIsHistoryPageOpen(true),
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { spaceMenuItems: menuItems, spaceMenu: true, onBackBtnClick: onClose, title: getSpaceName(space) }),
        React.createElement("h4", { style: { color: 'var(--main-color)', textAlign: 'center', fontWeight: 600, fontSize: 24, marginTop: 10 } }, "Archived space"),
        React.createElement(BlockContainer, null,
            React.createElement(ItemsBlock, null),
            React.createElement(MediaBlock, null),
            React.createElement(CommentBlock, null)),
        isHistoryPageOpen && (React.createElement(TimeLineModal, { items: space?.history || [], onClose: () => setIsHistoryPageOpen(false), title: "Space timeline" }))));
};
export default ArchivedSpace;

import { MobileModalContainer } from '#Components/CommonStyled';
import React from 'react';
import Timeline from './TimeLine';
import Header from '#Components/Header/Header';
const TimeLineModal = ({ onClose, title, items }) => {
    return (React.createElement(MobileModalContainer, null,
        React.createElement(Header, { onBackBtnClick: onClose, onClose: onClose, title: title }),
        React.createElement(Timeline, { events: items })));
};
export default TimeLineModal;

import styled from 'styled-components';
export const StyledInput = styled.div `
  width: 100%;
  margin-bottom: ${({ $bottomMargin }) => $bottomMargin}px;
`;
export const Input = styled.input `
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    font-family: 'TT Travels', serif;
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
  }
  text-overflow: ellipsis;
  font-family: 'TT Travels', serif;
  transition: all 0.5s;
  font-size: 16px;
  line-height: 22px;
  background: none;
  width: calc(100% - 45px);
  max-width: 720px;
  font-weight: 400;
  border: none;

  position: relative;
  top: -16px;
  background: none;

  :focus {
    top: -8px;
  }

  :disabled {
    color: rgba(27, 27, 30, 0.5);
  }

  ${({ isFilled }) => isFilled &&
    `
      top: -8px;
    `}
`;
export const Container = styled.div `
  height: 66px;
  cursor: text;
  position: relative;
  border: 1px solid var(--main-gray);
  border-radius: 15px;
  padding: 12px;

  .input-icon {
    transition: all 0.25s;
    position: absolute;
    top: calc(50% - 8px);

    ${({ active }) => active &&
    `
    top: calc(50% - 2px);
    `}
  }
  ${({ withRightBtn }) => withRightBtn &&
    `
      border-radius: 15px 0 0 15px;
    `}

  ${({ error }) => error &&
    `
      border: 1px solid var(--main-color);
    `}
  ${({ active, error }) => active &&
    !error &&
    `
      border: 1px solid var(--main-black);
    `}

     ${({ disabled }) => disabled &&
    `
    background-color: #E6EBEF;
    border: 1px solid var(--main-gray);
    `}
`;
export const Description = styled.p `
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;

  /* @media (max-width: 640px) {
    font-size: 12px;
    line-height: 16px;
  } */
`;
export const ErrorText = styled(Description) `
  color: var(--main-color);
`;
export const RightIcon = styled.img `
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;
export const Placeholder = styled.div `
  position: relative;
  transition: all 0.25s;
  color: #1b1b1e;
  opacity: 0.3;
  height: 25px;

  ${({ active }) => active
    ? `
    top: 0;
    font-size: 12px;
    line-height: 16px;
    `
    : `
    top: 12px;
    font-size: 16px;
    line-height: 22px;
    `}
`;
export const CloseIcon = styled.img `
  position: absolute;
  right: 17px;
  cursor: pointer;
  width: 18px;
  filter: opacity(0.5);
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
`;

import { useEffect } from 'react';
import { useToast } from '../providers/ToastProvider';
export const useSuccessToastEffect = (message) => {
    const { addToast } = useToast();
    useEffect(() => {
        if (message && addToast) {
            addToast({
                message,
                type: 'success',
            });
        }
    }, [message]);
};

import { FormInput, Header, UnitBlock } from '#Components';
import { AddItemBLock, BlockContent, SpaceItem } from '#Components/CommonStyled';
import { BlockContainer } from '#Components/UnitBlock/UnitBlock';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Plus, SearchIcon } from '#Images';
import { useQuery } from '@tanstack/react-query';
import { ApiService } from '#Services';
import { createEmptyArray } from '#Utils/helpers';
import { ItemLoader } from '#Components/AddSpace/components/skeletons';
import { useErrorToastEffect } from '#Hooks/useErrorToastEffect';
import { useDebounce, useStores, useSuccessToastEffect } from '#Hooks';
import { observer } from 'mobx-react-lite';
import CreateUnitPage from '#Components/AddSpace/components/CreateUnitPage';
const api = new ApiService();
const StoragePage = () => {
    const { storageId } = useParams();
    const { appData } = useStores();
    const [createNew, setCreateNew] = useState(false);
    const [isCreated, setIsCreated] = useState(false);
    const [debouncedFilter, filter, setFilter] = useDebounce('', 700);
    const { data, error, isLoading, refetch } = useQuery({
        queryKey: ['storage', storageId, debouncedFilter],
        queryFn: () => api.getStorage(storageId, debouncedFilter),
    });
    useErrorToastEffect(error, 'Error with storage loading');
    useErrorToastEffect(appData.error, appData.error || 'Something went wrong');
    useSuccessToastEffect(isCreated ? 'Unit created successfully' : '');
    const handleSearchChange = (newSearch) => {
        setFilter(newSearch);
    };
    const onAddNewUnit = async (data) => {
        setIsCreated(false);
        const isAdded = await appData.addNewUnit(storageId || '', data);
        if (isAdded) {
            setCreateNew(false);
            setIsCreated(true);
            refetch();
        }
    };
    // const filteredUnits =
    //   data?.units.filter(
    //     unit => unit.id.toString().match(new RegExp(filter, 'i')) || unit.title.toString().match(new RegExp(filter, 'i')),
    //   ) || [];
    return (React.createElement(React.Fragment, null, createNew ? (React.createElement(CreateUnitPage, { btnProps: { title: 'Create', onClick: onAddNewUnit, isLoading: appData.process }, title: " Create new unit", onClose: () => setCreateNew(false) })) : (React.createElement(React.Fragment, null,
        React.createElement(Header, { title: data?.title || '' }),
        React.createElement(FormInput, { iconUrl: SearchIcon, name: 'Search unit', onChange: handleSearchChange, value: filter, bottomMargin: 16, onClear: () => setFilter('') }),
        React.createElement(BlockContainer, { "$gap": 8 },
            React.createElement(AddItemBLock, { onClick: () => setCreateNew(true) },
                React.createElement(BlockContent, null,
                    React.createElement("img", { width: 24, height: 24, src: Plus, alt: "add" }),
                    "Add unit")),
            isLoading ? (createEmptyArray(10).map((_, index) => React.createElement(ItemLoader, { key: `empty_${index}` }))) : (React.createElement(React.Fragment, null, data?.units.map(unit => (React.createElement(UnitBlock, { key: unit.id, size: "small", linkTo: `/storage/${storageId}/${unit.id}`, title: unit.title },
                React.createElement(SpaceItem, null, `${unit.spaces_ids.length} spaces`)))))))))));
};
export default observer(StoragePage);

import React, { useEffect } from 'react';
import { InfoRowLine, ColumnFlexContainer } from '../shared/total-sheet/components';
import { useErrorToastEffect, useStores } from '#Hooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useQuery } from '@tanstack/react-query';
import { ApiService } from '#Services';
import Loader from '#Components/shared/Loader';
import { getTimeSlotFromDateString } from '#Utils/helpers';
import { useParams } from 'react-router-dom';
import CollectionReturnItem from '#Components/CollectionReturnItem/CollectionReturnItem';
import { Services } from '#Types/storageReturnDTO';
const api = new ApiService();
const TotalSheetDetails = ({ returnId }) => {
    const { clientId } = useParams();
    const { storageReturnData } = useStores();
    const clientData = useQuery({
        queryKey: ['client', clientId],
        queryFn: ({ queryKey }) => {
            const [_, clientId] = queryKey;
            return api.getClient(Number(clientId));
        },
    });
    const prevOrdersData = useQuery({
        queryKey: ['prevOrders', clientId],
        queryFn: ({ queryKey }) => {
            const [_, clientId] = queryKey;
            return api.getListOfReturnOrders(Number(clientId));
        },
    });
    const filteredPrevOrders = prevOrdersData.data?.filter(prevOrder => prevOrder.services.includes(Services.STORAGE_RETURN) ||
        prevOrder.services.includes(Services.STORAGE_COLLECTION)) || [];
    const { data, isLoading, error } = useQuery({
        enabled: !!returnId,
        queryKey: ['returnItem', returnId],
        retryDelay: 4000,
        queryFn: ({ queryKey }) => {
            const [_, returnId] = queryKey;
            return api.getReturnOrder(returnId);
        },
    });
    useEffect(() => {
        if (data?.stage) {
            storageReturnData.setCurrentStatus(data?.stage);
        }
    }, [data?.stage]);
    useErrorToastEffect(error, 'Error with price calculation');
    if (isLoading || clientData.isLoading || prevOrdersData.isLoading) {
        return React.createElement(Loader, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ColumnFlexContainer, null,
            React.createElement(InfoRowLine, { "$bold": true },
                React.createElement("span", { style: { fontSize: 24, lineHeight: '32px' } },
                    "Price: \u00A3",
                    data?.total_price)),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "Order: ",
                    data?.hubspot_order_id,
                    " ")),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDDD3\uFE0F Date and time:",
                    ' ',
                    dayjs(data?.order_date).format('DD MMMM YYYY') +
                        ' | ' +
                        `${getTimeSlotFromDateString(data?.time_start || '00')}:00-${getTimeSlotFromDateString(data?.time_end || '00')}:00`)),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\u260E\uFE0F Contact number: ",
                    clientData.data?.phone || 'No phone number specified')),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDCDE Contact name: ",
                    clientData.data?.first_name)),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDCCD Collection address: ",
                    data?.collection_address)),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDCCD Delivery address: ",
                    data?.dropoff_address)),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83C\uDFE0 Dropoff floor: ",
                    data?.dropoff_floor,
                    " Lift: ",
                    data?.dropoff_elevator ? 'Yes' : 'No')),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDCAA People required: ",
                    data?.amount_of_movers)),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDE9A Vans required: ",
                    data?.amount_of_vehicles)),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDCCF Moving in duration: ",
                    data?.moving_in_length_str)),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDCCF Moving out duration: ",
                    data?.moving_out_length_str)),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDCCF En route duration: ",
                    data?.en_route_length_str)),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDCCF Client duration: ",
                    data?.client_length_str)),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDCDC Items:",
                    ' ',
                    data?.items.map((item, index) => (React.createElement("span", { key: index },
                        item.title,
                        " - ",
                        item.amount,
                        data?.items.length - 1 === index ? '' : ', '))))),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "Comment: ",
                    data?.comment || 'No comment'))),
        React.createElement("h3", { style: { margin: '32px 0 18px 0', fontWeight: 600, fontSize: 16, lineHeight: '24px' } }, "Previous"),
        React.createElement(ColumnFlexContainer, { style: { gap: 6 } }, filteredPrevOrders.map(order => (React.createElement(CollectionReturnItem, { key: order.hubspot_order_id, data: order }))))));
};
export default observer(TotalSheetDetails);

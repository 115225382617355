import { Header } from '#Components';
import { AddItemBLock, BlockContent, SpaceItem } from '#Components/CommonStyled';
import UnitBlock, { BlockContainer } from '#Components/UnitBlock/UnitBlock';
import { EditIcon, Plus, TrashBin } from '#Images';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ApiService } from '#Services';
import { useErrorToastEffect } from '#Hooks/useErrorToastEffect';
import { createEmptyArray, declOfNum, getSpaceName } from '#Utils/helpers';
import { ItemLoader } from '#Components/AddSpace/components/skeletons';
import RenameUnit from '#Components/AddSpace/components/RenameUnit';
import RemoveUnit from '#Components/AddSpace/components/RemoveUnit';
const api = new ApiService();
const UnitPage = () => {
    const { unitId, storageId } = useParams();
    const navigate = useNavigate();
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
    const { data, error, isLoading, refetch } = useQuery({
        queryKey: ['unit', { storageId, unitId }],
        queryFn: () => api.getUnit(storageId, unitId),
    });
    useErrorToastEffect(error, 'Error with unit loading');
    useEffect(() => {
        if (error) {
            navigate(`/storage/${storageId}`);
        }
        return () => {
            refetch();
        };
    }, [error]);
    const onChangeUnitInfo = () => {
        setIsRenameModalOpen(false);
        refetch();
    };
    const onAddSpaceClick = () => {
        navigate(`/storage/${storageId}/${unitId}/add`);
    };
    const menuItems = [
        {
            title: 'Edit unit',
            iconUrl: EditIcon,
            onClick: () => setIsRenameModalOpen(true),
        },
        {
            title: 'Delete space',
            iconUrl: TrashBin,
            onClick: () => setIsRemoveModalOpen(true),
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { unitMenu: true, unitMenuItems: menuItems, title: data?.title || `Unit ${unitId}`, subtitle: `Seal Number ${data?.seal_number}` }),
        React.createElement(BlockContainer, { "$gap": 8 },
            React.createElement(AddItemBLock, { onClick: onAddSpaceClick },
                React.createElement(BlockContent, null,
                    React.createElement("img", { src: Plus, alt: "add" }),
                    "Add space")),
            isLoading ? (createEmptyArray(7).map((_, index) => React.createElement(ItemLoader, { key: `empty_${index}` }))) : (React.createElement(React.Fragment, null, data?.spaces.map(space => {
                const totalItems = space.items.reduce((acc, item) => acc + item.amount, 0);
                return (React.createElement(UnitBlock, { key: space.id, size: "small", linkTo: `/storage/${storageId}/${unitId}/${space.id}`, title: getSpaceName(space) },
                    React.createElement(SpaceItem, null, `${totalItems}  ${declOfNum(totalItems, ['item', 'items', 'items'])}`)));
            })))),
        React.createElement(RenameUnit, { open: isRenameModalOpen, onClose: () => setIsRenameModalOpen(false), afterRename: onChangeUnitInfo }),
        isRemoveModalOpen && (React.createElement(RemoveUnit, { open: isRemoveModalOpen, onClose: () => setIsRemoveModalOpen(false), onRemove: () => { } }))));
};
export default UnitPage;

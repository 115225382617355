export var DesignFonts;
(function (DesignFonts) {
    DesignFonts["primary"] = "font-weight: 600; font-size: 20px;";
    DesignFonts["secondary"] = "font-weight: 600; font-size: 12px; line-height: 16px;";
    DesignFonts["smallPrint"] = "font-weight: 400; font-size: 12px; line-height: 16px;";
    DesignFonts["bodyDemiBold"] = "font-weight: 600; font-size: 16px; line-height: 22px;";
    DesignFonts["bodyRegular"] = "font-weight: 400; font-size: 16px; line-height: 22px;";
    DesignFonts["headlineH1"] = "font-weight: 600; font-size: 64px; line-height: 72px;";
    DesignFonts["headlineH3"] = "font-weight: 600; font-size: 48px; line-height: 56px;";
    DesignFonts["headlineH2"] = "font-weight: 600; font-size: 40px; line-height: 48px;";
    DesignFonts["headlineH4"] = "font-weight: 600; font-size: 32px; line-height: 40px;";
    DesignFonts["headlineH4_5"] = "font-weight: 600; font-size: 24px; line-height: 32px;";
    DesignFonts["headlineH5"] = "font-weight: 600; font-size: 20px; line-height: 28px;";
    DesignFonts["subLineS1"] = "font-weight: 400; font-size: 32px; line-height: 40px;";
    DesignFonts["subLineS2"] = "font-weight: 400; font-size: 24px; line-height: 32px;";
    DesignFonts["subLineS3"] = "font-weight: 400; font-size: 20px; line-height: 28px;";
    DesignFonts["bodySRegular"] = "font-weight: 400; font-size: 14px; line-height: 20px;";
    DesignFonts["bodySDemibold"] = "font-weight: 600; font-size: 14px; line-height: 20px;";
})(DesignFonts || (DesignFonts = {}));

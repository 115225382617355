import React from 'react';
import styled from 'styled-components';
const ItemContainer = styled.div `
  font-weight: 400;
  font-size: 16px;
  /* padding: 8px 0; */
  height: 48px;
  line-height: 22px;
  justify-content: space-between;
  display: flex;
  gap: 32px;

  align-items: center;
  border-bottom: none;

  ${({ $inModal }) => $inModal &&
    `
    :not(:last-child) {
    border-bottom: 1px solid #e6ebef;
    height: 56px;
    }

  `}
`;
const PlusBtn = styled.div `
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ::after,
  ::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2px;
    height: 12px;
    background-color: var(--main-black);
  }
  ::before {
    width: 12px;
    height: 2px;
  }
`;
const ItemBtn = styled.span `
  width: 32px;
  height: 32px;
  margin: 0 4px;
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--main-black);
`;
const BoxInput = styled.input `
  width: 56px;
  height: 40px;
  padding: 12px;
  border-radius: 12px;
  font-size: 16px;
  border: none;
  text-align: center;
  background: transparent;
  background-color: var(--main-light-gray);
`;
const RemoveItemBtn = styled.button `
  border: none;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: #f04438;
  color: #fff;
`;
const ControllerContainer = styled.div `
  display: flex;
  align-items: center;
  background-color: ${({ $disabled }) => ($disabled ? 'transparent' : 'var(--main-light-gray)')};
  border-radius: 12px;
`;
const AddBtn = styled.button `
  font-weight: 600;
  font-size: 16px;
  padding: 11px;
`;
const LeftItemBlock = styled.div `
  display: flex;
  align-items: center;

  button {
    margin-right: 8px;
  }
`;
const CartItem = ({ amount, title, onChange, onRemove, edit, isSelected, onSelect, inModal }) => {
    const onIncrease = () => {
        onChange(String(Number(amount) + 1));
    };
    const onDecrease = () => {
        if (amount - 1 <= 0) {
            return onRemove();
        }
        onChange(String(Number(amount) - 1));
    };
    const onSetInputValue = (v) => {
        if (!v) {
            return onChange('0');
        }
        onChange(v);
    };
    return (React.createElement(ItemContainer, { "$inModal": inModal },
        React.createElement(LeftItemBlock, null,
            edit && !onSelect && React.createElement(RemoveItemBtn, { onClick: onRemove }, "\u2014"),
            React.createElement("p", null, title)),
        !isSelected && onSelect ? (React.createElement(AddBtn, { onClick: onSelect }, "Add")) : (React.createElement(ControllerContainer, { "$disabled": !edit && !onSelect },
            edit && React.createElement(ItemBtn, { onClick: onDecrease }, "\u2014"),
            React.createElement(BoxInput, { style: { fontWeight: 600 }, disabled: !edit, onChange: v => onSetInputValue(v.target.value), value: amount }),
            edit && (React.createElement(ItemBtn, { onClick: onIncrease },
                React.createElement(PlusBtn, null)))))));
};
export default CartItem;

import * as Sentry from '@sentry/browser';
import React, { Component } from 'react';
class ErrorBoundary extends Component {
    state = {
        hasError: false,
        message: '',
    };
    componentDidCatch(error, info) {
        console.log('Error: ', error);
        console.log('Info: ', info);
        this.setState({ hasError: true, message: `${error}` });
        Sentry.captureException(error);
    }
    render() {
        if (this.state.hasError) {
            return (React.createElement("div", { className: 'main-block' },
                React.createElement("div", { style: {
                        height: '100vh ',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    } },
                    React.createElement("span", { style: { fontWeight: 'bold', fontSize: '14px', marginTop: '30px' } }, "Oops, something went wrong"),
                    React.createElement("p", { style: { marginTop: 40 } }, this.state.message))));
        }
        return this.props.children;
    }
}
export default ErrorBoundary;

import React, { useEffect, useState } from 'react';
import SwipeContainer from '../SwipeContainer';
import { HeaderButton, HeaderButtons } from '#Components/CommonStyled';
import Loader from '#Components/shared/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiService } from '#Services';
import { useToast } from '../../../providers/ToastProvider';
const api = new ApiService();
const RemoveUnit = ({ onClose, open, onRemove }) => {
    const { addToast } = useToast();
    const [updating, setUpdating] = useState(false);
    const { unitId, storageId } = useParams();
    const navigate = useNavigate();
    const [beforeRemoveTimer, setBeforeRemoveTimer] = useState(5000);
    useEffect(() => {
        const timer = setInterval(() => {
            setBeforeRemoveTimer(t => t - 1000);
        }, 1000);
        if (beforeRemoveTimer === 0) {
            clearInterval(timer);
        }
        return () => {
            clearInterval(timer);
        };
    }, [beforeRemoveTimer]);
    const onRemoveUnit = async () => {
        try {
            setUpdating(true);
            await api.removeUnit(Number(unitId));
            addToast &&
                addToast({
                    message: 'Unit successfully removed',
                    type: 'success',
                });
            navigate(`/storage/${storageId}`);
        }
        catch (error) {
            addToast &&
                addToast({
                    message: 'Something went wrong',
                    type: 'error',
                });
        }
        finally {
            setUpdating(false);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SwipeContainer, { opened: open, onClose: onClose },
            React.createElement("div", { id: "transfer_unit_list", style: { display: 'flex', flexDirection: 'column', minHeight: '80vh' } },
                React.createElement(HeaderButtons, null,
                    React.createElement(HeaderButton, { onClick: onClose }, "Cancel"),
                    React.createElement(HeaderButton, { disabled: beforeRemoveTimer > 0, 
                        // $active={!!selectedUnitId && !transferring}
                        onClick: onRemoveUnit }, "Remove")),
                !!beforeRemoveTimer && React.createElement("p", null,
                    "You can remove unit after ",
                    beforeRemoveTimer / 1000,
                    " seconds"),
                updating && React.createElement(Loader, null)))));
};
export default RemoveUnit;

import { makeAutoObservable, runInAction } from 'mobx';
import ApiService from '#Services/apiService';
import dayjs from 'dayjs';
import { addSlotToDate, getTimeSlotHours, toIsoString } from '#Utils/helpers';
import { getStorageAddressById, storageIdToTitle } from '#Services/storage';
import AppData from './AppData';
import { sixHoursTimeSlotsFull, twoHoursTimeSlotsFull } from '#Constants/dateConst';
const api = new ApiService();
const defaultAddress = {
    type: 'house',
    address: '',
    postcode: '',
    elevator: true,
    parking: true,
    floor: '',
    location: { lat: 0, lng: 0 },
};
class StorageReturnData {
    date = dayjs().add(7, 'day').toDate();
    isSixHoursTimeSlots = true;
    timeSlot = [9, 15];
    totalPrice = 0;
    totalPricePerSlot = [];
    totalCountSheet = null;
    calculationId = null;
    currentStatus = null;
    calculateAbortController = null;
    clientInfo = null;
    address = defaultAddress;
    currentStepIndex = 0;
    items = [];
    contactData = {
        email: '',
        phone: '',
        comment: '',
    };
    constructor() {
        makeAutoObservable(this);
    }
    clean = () => {
        this.currentStatus = null;
        this.currentStepIndex = 0;
        this.address = defaultAddress;
        this.date = dayjs().add(7, 'day').toDate();
        this.isSixHoursTimeSlots = true;
        this.totalPricePerSlot = [];
        this.timeSlot = [9, 15];
        this.totalPrice = 0;
        this.calculationId = null;
        this.items = [];
        this.contactData = {
            email: '',
            phone: '',
            comment: '',
        };
        this.clientInfo = null;
    };
    setCurrentStepIndex = (index) => {
        this.currentStepIndex = index;
    };
    setCalculationId = (id) => {
        this.calculationId = id;
    };
    setTotalCount = (t = null) => {
        this.totalCountSheet = t;
    };
    setCurrentStatus = (status) => {
        this.currentStatus = status;
    };
    setClientInfo = (client) => {
        this.clientInfo = {
            first_name: client.first_name,
            phone: client.phone,
            last_name: client.last_name,
            storage_title: client.storage_location_ids[0]
                ? storageIdToTitle[client.storage_location_ids[0]]
                : null,
            storage_id: client.storage_location_ids[0],
            items: client.spaces.reduce((acc, space) => {
                acc.push(...space.items);
                return acc;
            }, []),
        };
    };
    setIsSixHrsTimeSlot = (v) => {
        this.isSixHoursTimeSlots = v;
    };
    setAddress = (address) => {
        this.address = address;
    };
    setDate = (date) => {
        this.date = date;
    };
    setTimeSlot = (timeSlot) => {
        this.timeSlot = timeSlot;
    };
    setItems = (items) => {
        this.items = items;
    };
    updateItem = (item) => {
        const index = this.items.findIndex(i => i.key === item.key);
        if (index !== -1) {
            this.items[index] = item;
        }
    };
    returnDataToCalcDto = (id) => {
        const startDate = toIsoString(addSlotToDate(this.date, this.timeSlot[0]));
        return {
            moving: {
                datetime: startDate,
                slot_duration_hours: getTimeSlotHours(this.timeSlot),
                destination_location: {
                    address: this.address.address,
                    elevator: this.address.elevator,
                    floor: Number(this.address.floor),
                    location: this.address.location,
                    parking: this.address.parking,
                    type: this.address.type,
                    postcode: this.address.postcode,
                    items: this.items.map(item => ({
                        amount: item.amount,
                        key: item.category || 'other',
                        comment: item.comment,
                        title: item.title,
                    })),
                },
                origin_location: {
                    address: getStorageAddressById(id).address,
                    elevator: true,
                    floor: 0,
                    location: {
                        lat: getStorageAddressById(id).location.Lat,
                        lng: getStorageAddressById(id).location.Lng,
                    },
                    parking: true,
                    type: 'house',
                    postcode: getStorageAddressById(id).postcode,
                    items: this.items.map(item => ({
                        amount: item.amount,
                        key: item.category || 'other',
                        comment: item.comment,
                        title: item.title,
                    })),
                },
            },
        };
    };
    createOrder = async (userId) => {
        try {
            AppData.setError(null);
            AppData.setProcess(true);
            const hubSpotId = await api.postCreateHubSpotOrder({
                calculation_id: this.calculationId || '',
                user_id: userId,
            });
            return hubSpotId.deal_id;
        }
        catch (error) {
            AppData.setError('Problem with order adding');
            console.log(error);
            return null;
        }
        finally {
            AppData.setProcess(false);
        }
    };
    calculatePrice = async () => {
        try {
            AppData.setError(null);
            AppData.setProcess(true);
            runInAction(() => {
                if (this.calculateAbortController) {
                    this.calculateAbortController.abort();
                }
                this.calculateAbortController = new AbortController();
            });
            const twoHrs = twoHoursTimeSlotsFull.map(time => {
                const startDate = toIsoString(addSlotToDate(this.date, time[0]));
                return { datetime: startDate, slot_duration_hours: getTimeSlotHours(time) };
            });
            const sixHrs = sixHoursTimeSlotsFull.map(time => {
                const startDate = toIsoString(addSlotToDate(this.date, time[0]));
                return { datetime: startDate, slot_duration_hours: getTimeSlotHours(time) };
            });
            const multipleRes = await api.calculateOrderWithMultipleSlots({
                ...this.returnDataToCalcDto(this.clientInfo.storage_id),
                slots: [...sixHrs, ...twoHrs],
            }, this.calculateAbortController?.signal);
            runInAction(() => {
                this.calculationId = multipleRes[0]?.id;
                this.totalPricePerSlot = multipleRes;
                this.totalCountSheet = multipleRes[0]?.response.moving;
            });
            AppData.setProcess(false);
        }
        catch (error) {
            if (error?.name === 'CanceledError') {
                console.log('Request aborted');
            }
            else {
                AppData.setError('Problem with space adding');
                AppData.setProcess(false);
                console.log(error);
            }
        }
        finally {
            this.calculateAbortController = null;
        }
    };
    setContactData = (data) => {
        this.contactData = data;
    };
}
export default new StorageReturnData();

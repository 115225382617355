import styled from 'styled-components';
export const TopButtonsWrapper = styled.div `
  display: flex;
  gap: 8px;
  button {
    font-size: 17px;
    padding: 12px 25px;
  }
`;
export const BottomButtonsWrapper = styled.div `
  margin-top: 20px;
`;

import { DesignFonts } from '../../style/DesignFonts';
import styled from 'styled-components';
export const FormWrapper = styled.div `
  max-width: 390px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
`;
export const FormContainer = styled.form `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 50%;

  h5 {
    margin-bottom: 20px;
    ${DesignFonts.headlineH5}
  }
`;
export const ResetPwLinkContainer = styled.div `
  margin-top: 8px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  a {
    text-decoration: none;
    color: var(--main-black);
  }
`;
export const CheckBoxContainer = styled.div `
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  input {
    margin-right: 10px;
  }
`;
export const FormDelimiter = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    margin: 0 40px;
  }
`;
export const FormDelimiterLine = styled.div `
  width: 190px;
  height: 1px;
  margin: 46px 0;
  background-color: rgba(27, 27, 30, 0.3); ;
`;
export const SocialButtonsBlock = styled.div `
  width: 100%;
  margin-bottom: 40px;
  button:not(:last-child) {
    margin-bottom: 20px;
  }
`;
export const SingnUpLink = styled.div `
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  a {
    text-decoration: none;
    color: var(--main-color);
  }
`;
export const Checkbox = styled.input `
  height: 18px;
  width: 18px;
  cursor: pointer;

  border-radius: 2px;
  border: 2px solid ${props => (props.primary ? 'var(--main-color)' : 'var(--main-black)')};
  color: hsl(0, 0%, 20%);
  position: relative;

  &:hover {
    border-color: var(--main-color);
  }

  &:checked {
    background-color: var(--main-color);
    border: none;
    &:hover {
      border-color: var(--color-gray);
    }
    &:after {
      position: absolute;
      display: table;
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      transition: all 0.2s ease;
      content: ' ';
    }
  }

  &:disabled {
    background-color: hsl(0, 0%, 95%);
    cursor: initial;
    &:hover {
      border-color: #cdd7e0;
    }
  }

  &:after {
    top: 48%;
    left: calc(50% - 5px);
    width: 6px;
    height: 10px;
    content: ' ';
  }
`;
export const Spinner = styled.svg `
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};
  margin-right: 8px;

  & .path {
    stroke: var(--main-color);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

import React from 'react';
import styled from 'styled-components';
import Toggle from '../Toggle/Toggle';
const Row = styled.div `
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 8px;
  cursor: pointer;
  width: fit-content;
`;
const ToggleRow = ({ title, iconUrl, onChange, isChecked, label }) => {
    const onRowClick = () => {
        onChange(!isChecked);
    };
    return (React.createElement(Row, null,
        React.createElement(Toggle, { checked: isChecked, label: label, onToggle: onRowClick }),
        React.createElement("span", { onClick: onRowClick }, title)));
};
export default ToggleRow;

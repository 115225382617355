import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { SearchIcon } from '#Images';
import { declOfNum } from '#Utils/helpers';
import { FormInput } from './shared';
import { SuggestionsContentContainer } from './shared/Dropdown';
import Button, { ButtonKinds } from './shared/Button/Button';
import { DesignFonts } from '../style/DesignFonts';
import { useErrorToastEffect, useStores, useSuccessToastEffect } from '#Hooks';
import Loader from './shared/Loader';
import { v4 as uuidv4 } from 'uuid';
const Container = styled.div `
  display: flex;
  flex-direction: column;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  z-index: 999;
  position: fixed;
  max-width: calc(390px - 30px);
  width: calc(100vw - 30px);
  top: 0;
  margin: 0 auto;
  padding-top: 16px;
  background-color: #fff;
`;
const AddBtn = styled(Button) `
  padding: 8px 20px;
  ${DesignFonts.secondary}
`;
const ItemsQty = styled.span `
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
`;
const NothingFound = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  > span {
    ${DesignFonts.bodyRegular}
    color: var(--main-gray);
    margin-bottom: 12px;
  }
`;
const MobileItemPicker = ({ renderSuggestions, setIsOpen, totalItems, onAddNewItem }) => {
    const [mobileFilter, setMobileFilter] = useState('');
    const { appData } = useStores();
    useErrorToastEffect(appData.error, appData.error || 'Something went wrong');
    useSuccessToastEffect(appData.success ? 'Item added successfully' : '');
    const contRef = useRef(null);
    useEffect(() => {
        const vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        if (contRef.current) {
            contRef.current.style.setProperty('--vh', `${vh}px`);
        }
    }, []);
    const onAddItem = async () => {
        const newItemUUID = uuidv4();
        const item = {
            title: mobileFilter,
            key: mobileFilter.replace(' ', '_').toLowerCase(),
            id: newItemUUID,
        };
        const createdItem = await appData.addNewItem(item);
        if (createdItem) {
            const itemToAdd = { ...createdItem, amount: 1 };
            onAddNewItem(itemToAdd);
        }
    };
    return (React.createElement(React.Fragment, null,
        appData.process && React.createElement(Loader, null),
        React.createElement(Container, { ref: contRef, id: "mobile-item-picker" },
            React.createElement(FormInput, { iconUrl: SearchIcon, name: 'Items', onChange: f => setMobileFilter(f), value: mobileFilter, bottomMargin: 16 }),
            renderSuggestions(mobileFilter).length ? (React.createElement(SuggestionsContentContainer, { style: { height: '100%' } }, renderSuggestions(mobileFilter))) : (React.createElement("div", { style: { height: '100%' } },
                React.createElement(NothingFound, null,
                    React.createElement("span", null, " Nothing found "),
                    React.createElement(AddBtn, { disabled: appData.process, kind: ButtonKinds.secondary, onClick: onAddItem }, "Add new item")))),
            React.createElement("div", { style: { padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' } },
                totalItems > 0 && (React.createElement(ItemsQty, null, `${totalItems} ${declOfNum(totalItems, [
                    'Item',
                    'Items',
                    'Items',
                ])} added to inventory`)),
                React.createElement(Button, { full: true, type: "submit", onClick: setIsOpen, kind: ButtonKinds.primary }, "Done")))));
};
export default observer(MobileItemPicker);

import { CircleChart } from '#Components/shared';
import { getChartColorByPercent } from '#Components/shared/CircleChart/CircleChart';
import React from 'react';
import { DesignFonts } from '../../style/DesignFonts';
import styled from 'styled-components';
const ContentBlock = styled.div `
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const BottomBlock = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const Indicator = styled.div `
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--main-gray);
`;
const IndicatorBlock = styled.div `
  ${DesignFonts.smallPrint}
  align-items: center;
  display: flex;
  & > div {
    margin-right: 4px;
  }
`;
const ChartBlock = ({ percentage }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(ContentBlock, null,
            React.createElement(CircleChart, { percentage: percentage }),
            React.createElement(BottomBlock, null,
                React.createElement(IndicatorBlock, null,
                    React.createElement(Indicator, { style: { backgroundColor: getChartColorByPercent(percentage) } }),
                    React.createElement("span", null, "booked")),
                React.createElement(IndicatorBlock, null,
                    React.createElement(Indicator, null),
                    React.createElement("span", null, "free"))))));
};
export default ChartBlock;

import React from 'react';
import { Spinner } from './Button/Components';
const Loader = () => {
    return (React.createElement("div", { style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            background: 'transparent',
            position: 'fixed',
            zIndex: 999999,
        } },
        React.createElement(Spinner, { width: 42, height: 42, viewBox: "0 0 50 50" },
            React.createElement("circle", { className: "path", cx: "25", cy: "25", r: "20", fill: "none", strokeWidth: "5" }))));
};
export default Loader;

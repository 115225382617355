import React, { useState } from 'react';
import { Container, SuggestionRow, SuggestionsContainer } from './Dropdown';
import styled from 'styled-components';
import useOnclickOutside from 'react-cool-onclickoutside';
import { SearchIcon } from '#Images';
import FormInput from './FormInput/FormInput';
const StyledContainer = styled(SuggestionsContainer) `
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  max-height: 208px;
`;
const StyledContentContainer = styled.div `
  overflow-y: auto;
`;
const StyledRow = styled(SuggestionRow) `
  margin: 0 !important;
  padding: 8px 12px !important;
  ${({ $selected }) => $selected && `background-color: #e6ebef50;`};
`;
const SearchInputContainer = styled.div `
  border-bottom: 1px solid var(--main-black);
  padding: 12px;
  gap: 12px;
  display: flex;
  align-items: center;
`;
const SearchInput = styled.input `
  border: none;
  background-color: transparent;
  width: 100%;
`;
const EmptyItemsPlaceholder = styled.p `
  padding: 12px;
  color: var(--main-grey);
  text-align: center;
`;
const Combobox = ({ isDisabled, defaultValue, title, description, errorText, onChange, items }) => {
    const [suggestions, setSuggestions] = useState(items);
    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const ref = useOnclickOutside(() => {
        setIsOpen(false);
        setInputValue('');
    });
    const handleInput = (v) => {
        setInputValue(v);
        setSuggestions(items.filter(item => item.toLowerCase().startsWith(v.toLowerCase())));
    };
    const handleSelect = (item) => {
        onChange(item);
        setIsOpen(false);
        setInputValue('');
    };
    const renderSuggestions = () => !suggestions.length ? (React.createElement(EmptyItemsPlaceholder, null, "No items found")) : (suggestions.map(val => {
        return (React.createElement(StyledRow, { "$selected": val === defaultValue, key: val, onClick: () => handleSelect(val) }, val));
    }));
    return (React.createElement(Container, { ref: ref },
        React.createElement(FormInput, { comboArrows: true, onFocus: () => setIsOpen(true), errorText: errorText, name: title || '', onChange: handleInput, value: defaultValue, description: description, iconUrl: '', disabled: isDisabled }),
        isOpen && (React.createElement(StyledContainer, null,
            React.createElement(SearchInputContainer, null,
                React.createElement("img", { src: SearchIcon, alt: "search icon", width: 16, height: 16 }),
                React.createElement(SearchInput, { placeholder: title, value: inputValue, onChange: e => handleInput(e.target.value) })),
            React.createElement(StyledContentContainer, null, renderSuggestions())))));
};
export default Combobox;

import { BlockContent, StyledIconBtnBLock } from '#Components/CommonStyled';
import React from 'react';
import { DesignFonts } from '../../style/DesignFonts';
import styled from 'styled-components';
const Title = styled.span `
  ${DesignFonts.bodyRegular}
`;
const IconBtnBLock = ({ iconUrl, title, onClick, full, children, disabled, lined }) => {
    return (React.createElement(StyledIconBtnBLock, { "$lined": lined, "$full": full, "$thin": true, onClick: disabled ? undefined : onClick },
        React.createElement(BlockContent, { "$lined": lined },
            React.createElement("img", { style: { filter: disabled ? 'opacity(0.5)' : '' }, src: iconUrl, alt: "icon" }),
            React.createElement(Title, { style: { filter: disabled ? 'opacity(0.5)' : '' } }, title),
            children)));
};
export default IconBtnBLock;

import React from 'react';
import { ButtonText, Spinner, StyledButton } from './Components';
export var ButtonKinds;
(function (ButtonKinds) {
    ButtonKinds["primary"] = "primary";
    ButtonKinds["secondary"] = "secondary";
})(ButtonKinds || (ButtonKinds = {}));
export const Button = React.forwardRef((props, ref) => {
    return (React.createElement(StyledButton, { disabled: props.loading || props.disabled, ...props },
        React.createElement(ButtonText, { loading: props.loading }, props.children),
        props.loading && (React.createElement(Spinner, { width: 42, height: 42, viewBox: "0 0 50 50" },
            React.createElement("circle", { className: "path", cx: "25", cy: "25", r: "20", fill: "none", strokeWidth: "5" })))));
});
export default Button;

import React, { useState } from 'react';
import styled from 'styled-components';
import { ArrowLeft, ThreeDots, Logo, Plus } from '#Images';
import { useNavigate } from 'react-router-dom';
import { DesignFonts } from '../../style/DesignFonts';
import Menu from './Menu';
import { useAuth } from '../../providers/AuthProvider';
const HeaderContainer = styled.div `
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  display: grid;
  grid-template-columns: 1fr 5fr 1fr; /* Первый столбец размером по содержимому, второй занимает оставшееся пространство */
  align-items: center; /* Выравнивание по вертикали по центру */
  height: 50px;
  margin-bottom: 12px;
`;
const Title = styled.span `
  text-align: center;
  ${DesignFonts.bodyDemiBold}
`;
const Subtitle = styled.span `
  ${DesignFonts.smallPrint}
  color: var(--main-gray-dark);
`;
const TitleBlock = styled.div `
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
`;
const LeftImgWrapper = styled.figure `
  justify-self: start;
  cursor: pointer;
`;
const Avatar = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-light-gray);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  ${DesignFonts.secondary}
`;
const RightAvatarContainer = styled.div `
  display: flex;
  align-items: center;
  justify-self: end;
  ${Avatar} {
    margin-right: 10px;
  }
`;
const LogoutBtn = styled.button `
  background: none;
  border: none;
  cursor: pointer;
  ${DesignFonts.bodyDemiBold}
`;
const TitlePageBlock = styled(HeaderContainer) `
  grid-template-columns: auto auto;
`;
const RightImgWrapper = styled.figure `
  justify-self: end;
  cursor: pointer;
`;
const Header = ({ subtitle, title, spaceMenu, unitMenu, titlePage, onBackBtnClick, spaceMenuItems, unitMenuItems, orderMenuItems, clientMenuItems, onClose, }) => {
    const [isSpaceMenuOpen, setIsSpaceMenuOpen] = useState(false);
    const [isUnitMenuOpen, setIsUnitMenuOpen] = useState(false);
    const [isOrderMenuOpen, setIsOrderMenuOpen] = useState(false);
    const [isClientMenuOpen, setIsClientMenuOpen] = useState(false);
    const navigate = useNavigate();
    const { signOut } = useAuth();
    const onBackCLick = () => {
        if (onBackBtnClick) {
            return onBackBtnClick();
        }
        const pathSegments = window.location.pathname.split('/').filter(Boolean);
        if (pathSegments.length > 1) {
            const parentPath = '/' + pathSegments.slice(0, -1).join('/');
            navigate(parentPath);
        }
        else {
            navigate('/');
        }
    };
    return (React.createElement(React.Fragment, null,
        titlePage ? (React.createElement(TitlePageBlock, null,
            React.createElement("img", { width: 84, height: 'auto', src: Logo, alt: 'logo' }),
            React.createElement(RightAvatarContainer, null,
                React.createElement(Avatar, null, "EN"),
                React.createElement(LogoutBtn, { onClick: () => signOut() }, "Log Out")))) : (React.createElement(HeaderContainer, null,
            React.createElement(LeftImgWrapper, { onClick: onBackCLick },
                React.createElement("img", { src: ArrowLeft })),
            React.createElement(TitleBlock, null,
                React.createElement(Title, null, title),
                subtitle && React.createElement(Subtitle, null, subtitle)),
            !onClose && spaceMenu && (React.createElement(RightImgWrapper, { onClick: () => setIsSpaceMenuOpen(!isSpaceMenuOpen) },
                React.createElement("img", { src: ThreeDots }))),
            !onClose && unitMenu && (React.createElement(RightImgWrapper, { onClick: () => setIsUnitMenuOpen(!isUnitMenuOpen) },
                React.createElement("img", { src: ThreeDots }))),
            !onClose && orderMenuItems && (React.createElement(RightImgWrapper, { onClick: () => setIsOrderMenuOpen(!isOrderMenuOpen) },
                React.createElement("img", { src: ThreeDots }))),
            !onClose && clientMenuItems && (React.createElement(RightImgWrapper, { onClick: () => setIsClientMenuOpen(!isClientMenuOpen) },
                React.createElement("img", { src: ThreeDots }))),
            onClose && (React.createElement(RightImgWrapper, { onClick: onClose },
                React.createElement("img", { style: { transform: 'rotate(45deg)' }, src: Plus }))))),
        isSpaceMenuOpen && React.createElement(Menu, { onClose: () => setIsSpaceMenuOpen(false), items: spaceMenuItems || [] }),
        isUnitMenuOpen && React.createElement(Menu, { onClose: () => setIsUnitMenuOpen(false), items: unitMenuItems || [] }),
        isOrderMenuOpen && React.createElement(Menu, { onClose: () => setIsOrderMenuOpen(false), items: orderMenuItems || [] }),
        isClientMenuOpen && React.createElement(Menu, { onClose: () => setIsOrderMenuOpen(false), items: clientMenuItems || [] })));
};
export default Header;

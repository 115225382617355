import React from 'react';
import styles from './ToggleSwitch.module.scss';
const Toggle = ({ label, onToggle, checked }) => {
    return (React.createElement("div", { className: styles['container'] },
        React.createElement("div", { className: styles['toggle-switch'] },
            React.createElement("input", { onChange: v => onToggle(v.target.checked), checked: checked, type: "checkbox", className: styles['checkbox-inp'], name: label, id: label }),
            React.createElement("label", { className: styles['label'], htmlFor: label },
                React.createElement("span", { className: styles['inner'] }),
                React.createElement("span", { className: styles['switch'] })))));
};
export default Toggle;

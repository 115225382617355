import { FormInput, Header } from '#Components';
import { SearchListItem } from '#Components/CommonStyled';
import Loader from '#Components/shared/Loader';
import { useDebounce, useErrorToastEffect, useStores } from '#Hooks';
import { ApiService } from '#Services';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const api = new ApiService();
const Clients = () => {
    const { appData } = useStores();
    const [debouncedFilter, filter, setFilter] = useDebounce('', 700);
    const navigate = useNavigate();
    const { data, error, isLoading, refetch } = useQuery({
        enabled: filter.length >= 3,
        queryKey: ['clients'],
        queryFn: () => api.getClients(filter),
    });
    useErrorToastEffect(error, 'Error with unit loading');
    useEffect(() => {
        if (debouncedFilter.length >= 3) {
            refetch();
        }
    }, [debouncedFilter]);
    useEffect(() => {
        if (!appData.storagePlans.length) {
            appData.fetchStoragePlans();
        }
    }, []);
    const onClientClick = (id) => {
        navigate(`/clients/${id}`);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { title: "Clients" }),
        React.createElement(FormInput, { onClear: () => setFilter(''), name: "Search by name or ID", onChange: setFilter, value: filter }),
        isLoading ? (React.createElement(Loader, null)) : (React.createElement("div", { style: { marginTop: 20 } }, data?.map(client => (React.createElement(SearchListItem, { onClick: () => onClientClick(`${client.id}`), key: client.id },
            React.createElement("span", null,
                client.first_name,
                " ",
                client.last_name),
            React.createElement("p", null,
                client.space_ids.length,
                " spaces"))))))));
};
export default observer(Clients);

import 'react-datepicker/dist/react-datepicker.css';
import styles from './datePicker.module.scss';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { months } from '#Constants/dateConst';
const DatePicker = ({ value, onChange, filterDate, minDate }) => (React.createElement(ReactDatePicker, { filterDate: filterDate || undefined, disabledKeyboardNavigation: true, renderCustomHeader: ({ decreaseMonth, increaseMonth, date, prevMonthButtonDisabled, nextMonthButtonDisabled }) => {
        const curMonth = new Date(date).getMonth();
        const curYear = new Date(date).getFullYear();
        return (React.createElement("div", { className: styles['datepicker-header'] },
            React.createElement("button", { "aria-label": "btn-left", type: "button", className: `${styles['month-changer']} ${styles['month-changer_left']}`, onClick: decreaseMonth, disabled: prevMonthButtonDisabled }),
            React.createElement("div", { className: styles['datepicker-header__month'] },
                months[curMonth],
                " ",
                React.createElement("span", { className: styles['datepicker-header__year'] }, curYear)),
            React.createElement("button", { "aria-label": "btn-right", type: "button", className: `${styles['month-changer']} ${styles['month-changer_right']}`, onClick: increaseMonth, disabled: nextMonthButtonDisabled })));
    }, 
    // temp fix for no default value
    selected: value || '', className: styles['datepicker-calendar'], inline: true, calendarClassName: styles['datepicker-body'], dayClassName: date => styles['datepicker-days'], onChange: date => onChange(date), minDate: minDate || new Date() }));
export default DatePicker;

import React from 'react';
import { ColumnFlexContainer, InfoRowLine } from '../shared/total-sheet/components';
import { useErrorToastEffect, useStores } from '#Hooks';
import dayjs from 'dayjs';
import { Button } from '#Components/shared';
import { BottomButtonsWrapper } from '../shared/components';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import CollectionReturnItem from '#Components/CollectionReturnItem/CollectionReturnItem';
import { getStorageAddressById } from '#Services/storage';
import { useQuery } from '@tanstack/react-query';
import Loader from '#Components/shared/Loader';
import { ApiService } from '#Services';
import { hoursDurationToTimeString } from '#Utils/helpers';
import { Services } from '#Types/storageReturnDTO';
const api = new ApiService();
const TotalSheet = () => {
    const { storageReturnData: { totalCountSheet, clientInfo, date, timeSlot, address, items, createOrder }, appData: { process, error }, } = useStores();
    const { clientId } = useParams();
    const navigate = useNavigate();
    const onConfirmClick = async () => {
        const hubSpotId = await createOrder(Number(clientId));
        if (hubSpotId) {
            navigate(`/clients/${clientId}/return/${hubSpotId}`);
        }
    };
    const prevOrdersData = useQuery({
        queryKey: ['prevOrders', clientId],
        queryFn: ({ queryKey }) => {
            const [_, clientId] = queryKey;
            return api.getListOfReturnOrders(Number(clientId));
        },
    });
    const filteredPrevOrders = prevOrdersData.data?.filter(prevOrder => prevOrder.services.includes(Services.STORAGE_RETURN) ||
        prevOrder.services.includes(Services.STORAGE_COLLECTION)) || [];
    useErrorToastEffect(error, 'Error with order creating');
    useErrorToastEffect(error, 'Error with price calculation');
    // useEffect(() => {
    //   calculatePrice();
    // }, []);
    if (prevOrdersData.isLoading) {
        return React.createElement(Loader, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ColumnFlexContainer, null,
            React.createElement(InfoRowLine, { "$bold": true }, process ? (React.createElement("span", null, "...calculating")) : (React.createElement("span", { style: { fontSize: 24, lineHeight: '32px' } },
                "Price: \u00A3",
                totalCountSheet?.total_price_with_discount))),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDDD3\uFE0F Date and time: ",
                    dayjs(date).format('DD MMMM YYYY') + ' | ' + `${timeSlot[0]}:00-${timeSlot[1]}:00`)),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\u260E\uFE0F Contact number: ",
                    clientInfo?.phone || 'No phone number specified')),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDCDE Contact name: ",
                    clientInfo?.first_name)),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDCCD Collection address:",
                    ' ',
                    clientInfo?.storage_id ? getStorageAddressById(clientInfo?.storage_id)?.address : 'Not defined')),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDCCD Delivery address: ",
                    address.address)),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83C\uDFE0 Dropoff floor: ",
                    address.floor,
                    " Lift: ",
                    address.elevator ? 'Yes' : 'No')),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDCAA People required: ",
                    totalCountSheet?.movers)),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDE9A Vans required: ",
                    totalCountSheet?.cars)),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDCCF Moving in duration: ",
                    hoursDurationToTimeString(totalCountSheet?.loading_duration || 0))),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDCCF Moving out duration: ",
                    hoursDurationToTimeString(totalCountSheet?.unloading_duration || 0))),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDCCF En route duration: ",
                    hoursDurationToTimeString(totalCountSheet?.loading_to_unloading_duration || 0))),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDCCF Client duration: ",
                    hoursDurationToTimeString(totalCountSheet?.duration_for_client || 0))),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null,
                    "\uD83D\uDCDC Items:",
                    ' ',
                    items.map((item, index) => (React.createElement("span", { key: index },
                        item.title,
                        " - ",
                        item.amount,
                        items.length - 1 === index ? '' : ', '))))),
            React.createElement("p", { style: { textAlign: 'right', fontSize: 12 } },
                "Total items: ",
                items.reduce((acc, item) => acc + item.amount, 0)),
            React.createElement(InfoRowLine, null,
                React.createElement("span", null, "Comment: No comment yet"))),
        React.createElement("h3", { style: { margin: '32px 0 18px 0', fontWeight: 600, fontSize: 16, lineHeight: '24px' } }, "Previous"),
        React.createElement(ColumnFlexContainer, { style: { gap: 6 } }, filteredPrevOrders.map(order => (React.createElement(CollectionReturnItem, { key: order.hubspot_order_id, data: order })))),
        React.createElement(BottomButtonsWrapper, null,
            React.createElement(Button, { onClick: onConfirmClick, full: true, kind: 'primary', loading: process, type: "button" }, "Confirm"))));
};
export default observer(TotalSheet);

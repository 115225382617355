import { BlockWithIcon } from '#Components/shared';
import { ChatIcon } from '#Images';
import React from 'react';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import { CommentItem, CommentItemBlock } from '#Components/AddSpace/components/CommentBlock';
const ClientCommentBlock = ({ comments }) => {
    return (React.createElement(BlockWithIcon, { iconUrl: ChatIcon, title: "Comments" }, !!comments.length ? (React.createElement(CommentItemBlock, null, comments.map((comment, index) => (React.createElement(CommentItem, { key: index },
        React.createElement("div", null,
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                React.createElement("span", null, dayjs(comment.created_at).format('DD.MM.YYYY HH:mm')),
                React.createElement("div", null,
                    " ",
                    comment.content)))))))) : (React.createElement("p", null, "No comments yet"))));
};
export default observer(ClientCommentBlock);

import { useEffect, useState } from 'react';
function useDebounce(initialValue, time) {
    const [value, setValue] = useState(initialValue);
    const [debouncedValue, setDebouncedValue] = useState(initialValue);
    useEffect(() => {
        const debounce = setTimeout(() => {
            setDebouncedValue(value);
        }, time);
        return () => {
            clearTimeout(debounce);
        };
    }, [value, time]);
    return [debouncedValue, value, setValue];
}
export default useDebounce;

import { Header, UnitBlock } from '#Components';
import { UnitBlockImage } from '#Components/CommonStyled';
import { BlockContainer } from '#Components/UnitBlock/UnitBlock';
import React from 'react';
import { StorageMan, StudentMan } from '#Images';
const Main = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { titlePage: true, title: "Storages" }),
        React.createElement(BlockContainer, null,
            React.createElement(UnitBlock, { linkTo: "/storage", title: "Storages" },
                React.createElement(UnitBlockImage, { src: StorageMan, alt: "storages" })),
            React.createElement(UnitBlock, { linkTo: "/clients", title: "Clients" },
                React.createElement(UnitBlockImage, { src: StudentMan, alt: "storages" })))));
};
export default Main;

import { Header } from '#Components';
import SpaceInfoLink from '#Components/shared/SpaceInfoLink';
import { useStores } from '#Hooks';
import { ApiService } from '#Services';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';
import ArchivedSpace from './ArchivedSpace';
import { observer } from 'mobx-react-lite';
const api = new ApiService();
const Archive = () => {
    const { clientId } = useParams();
    const { spaceData } = useStores();
    const { data } = useQuery({
        queryKey: ['client', clientId],
        queryFn: ({ queryKey }) => {
            const [_, clientId] = queryKey;
            return api.getClient(Number(clientId));
        },
    });
    const onaBackCLick = () => {
        spaceData.setSpace(null);
        spaceData.clean();
    };
    const onSpaceLinkClick = (space) => {
        spaceData.setSpaceData(space);
        spaceData.setSpace(space);
    };
    const archivedSpaces = data?.spaces.filter(space => space.active);
    return (React.createElement(React.Fragment, null, !spaceData.space ? (React.createElement(React.Fragment, null,
        React.createElement(Header, { title: React.createElement("span", { style: { color: 'var(--main-color)' } }, "Archived Spaces"), subtitle: `Client ID ${clientId}` }),
        !archivedSpaces?.length ? (React.createElement("h5", { style: { textAlign: 'center' } }, "No archived spaces yet")) : (archivedSpaces.map(space => React.createElement(SpaceInfoLink, { onClick: () => onSpaceLinkClick(space), space: space }))))) : (React.createElement(ArchivedSpace, { onClose: onaBackCLick, space: spaceData.space }))));
};
export default observer(Archive);

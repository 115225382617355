import DatePicker from '#Components/shared/DatePicker/DatePicker';
import React, { useState } from 'react';
import { Button } from '#Components/shared';
import { ButtonKinds } from '#Components/shared/Button/Button';
const MobileDatePicker = ({ onChange, value }) => {
    const [date, setDate] = useState(value);
    const onSelectClick = () => {
        onChange(date);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DatePicker, { onChange: setDate, value: date }),
        React.createElement("div", { style: { marginTop: '40px' } },
            React.createElement(Button, { full: true, type: "submit", onClick: onSelectClick, kind: ButtonKinds.primary }, "Select"))));
};
export default MobileDatePicker;

import { Header } from '#Components';
import { BlockContainer } from '#Components/UnitBlock/UnitBlock';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { ApiService } from '#Services';
import { StorageItemLoader } from '#Components/AddSpace/components/skeletons';
import { createEmptyArray } from '#Utils/helpers';
import { useErrorToastEffect } from '#Hooks/useErrorToastEffect';
import { StorageBLockTitle, StorageBlock } from '#Components/AddSpace/components/StorageBlock';
import { Link } from 'react-router-dom';
import { ArrowLink } from '#Images';
const api = new ApiService();
const Storages = () => {
    const { data, error, isLoading } = useQuery({
        queryKey: ['storages'],
        queryFn: api.getStorages,
    });
    useErrorToastEffect(error, 'Error with storages loading');
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { title: "Storages" }),
        React.createElement(BlockContainer, null, isLoading ? (createEmptyArray(3).map((item, index) => React.createElement(StorageItemLoader, { key: `empty_${index}` }))) : (React.createElement(React.Fragment, null, data?.map(storage => (React.createElement(Link, { key: storage.id, to: `/storage/${storage.id}` },
            React.createElement(StorageBlock, null,
                React.createElement("img", { style: { position: 'absolute', right: 20, top: 20 }, width: 20, height: 20, src: ArrowLink, alt: "add" }),
                React.createElement(StorageBLockTitle, null, storage.title))))))))));
};
export default Storages;

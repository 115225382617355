export const storages = [
    {
        id: 123,
        title: 'Storebox',
        load: 50,
        units: [
            {
                id: 221,
                seal_number: '332211',
                spaces_ids: [1, 2, 3],
                title: 'Unit 1',
            },
        ],
    },
];
export const storage = {
    id: 123,
    title: 'Storebox',
    load: 50,
    units: [
        {
            id: 221,
            seal_number: '332211',
            spaces_ids: [1, 2, 3],
            title: 'Unit 1',
        },
    ],
};
export const space = {
    id: 311,
    user_id: 445,
    unit_id: 221,
    storage_id: 123,
    created_at: '2021-02-01T00:00:00.000Z',
    history: null,
    user_name: 'John',
    hubspot_order_id: '1234',
    storage_location_id: 1,
    storage_title: 'Storebox',
    unit_title: 'Unit 1',
    media: [
        {
            id: '554',
            content_type: 'photo',
            url: 'https://cc-prod.scene7.com/is/image/CCProdAuthor/adobe-firefly-marquee-text-to-image-0-mobile-600x600?$pjpeg$&jpegSize=100&wid=600',
        },
        {
            id: '445',
            content_type: 'photo',
            url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0_pw1BpBfuZAevTRIg7RFQD1NZzdrEy016w&usqp=CAU',
        },
        {
            id: '223',
            content_type: 'video',
            url: 'https://vkvd45.mycdn.me/?expires=1698938426937&srcIp=109.236.80.200&pr=40&srcAg=CHROME&ms=45.136.22.135&type=1&sig=jKjTXi_PUUw&ct=0&urls=185.226.53.135&clientType=13&appId=512000384397&zs=43&id=1037337758283',
        },
    ],
    items: [
        {
            key: 'tv',
            title: 'TV',
            amount: 2,
        },
        {
            key: 'sofa',
            title: 'Sofa',
            amount: 3,
        },
    ],
    comments: [],
};
export const unit = {
    id: 221,
    seal_number: '332211',
    spaces_ids: [1, 2, 3],
    spaces: [space],
    title: 'Unit 1',
};
export const client = {
    id: 1,
    first_name: 'Client 1',
    last_name: 'Client 1',
    current_plan: 'plan 8x6',
    current_price_per_week: '223',
    duration: 'Monthly',
    start_date: new Date().toISOString(),
    estimated_end_date: new Date().toISOString(),
    email: 'b5tJt@example.com',
    hubspot_id: '123',
    end_date: new Date().toISOString(),
    space_ids: [1, 2, 3],
    storage_location_ids: [1],
    phone: '+44 7555 123 456',
    comments: [],
    spaces: [],
    items: [
        {
            key: 'tv',
            title: 'TV',
            amount: 2,
        },
        {
            key: 'sofa',
            title: 'Sofa',
            amount: 3,
        },
    ],
};
export const plans = [
    { key: 'plan3x5', title: 'Plan 3x5' },
    { key: 'plan6x4', title: 'Plan 6x4' },
    { key: 'plan6x6', title: 'Plan 6x6' },
    { key: 'plan8x6', title: 'Plan 8x6' },
    { key: 'plan9x8', title: 'Plan 9x8' },
    { key: 'plan12x8', title: 'Plan 12x8' },
];
export const duration = ['Monthly', 'Weekly'];
export const prices = {
    plan3x5: 5,
    plan6x4: 6,
    plan6x6: 7,
    plan8x6: 22,
    plan9x8: 32,
    plan12x8: 44,
};
export const newAddedFile = {
    id: 123,
};
const kock = {
    version: 3,
    next_step: true,
    detail_extra: {
        fields: [
            {
                key: 'initiator_fullname',
                value: 'Никерина Виктория Сергеевна',
                title: 'ФИО сотрудника',
                view_type: 'text',
                read_only: true,
            },
            {
                key: 'initiator_tn',
                value: '02076835',
                title: 'Табельный номер сотрудника',
                view_type: 'text',
                read_only: true,
            },
            {
                key: 'initiator_phone_number',
                value: '+79889999998',
                title: 'Номер телефона сотрудника',
                view_type: 'text',
                read_only: true,
            },
            {
                key: 'initiator_staff_name',
                value: 'Продавец-кассир',
                title: 'Должность сотрудника',
                view_type: 'text',
                read_only: true,
            },
            {
                key: 'initiator_org_unit_name',
                value: '23000 Пятерочка / Объект розницы / Кластер Москва 20 / Кластеры / Дивизион Москва Москва-Запад / Дивизионы / Макрорегион Москва / Макрорегионы / Территория Центр / Торговая сеть Пятерочка',
                title: 'Подразделение',
                view_type: 'text',
                read_only: true,
            },
            { key: 'initiator_bend', value: 'LM', title: 'Бэнд сотрудника', view_type: 'text', read_only: true },
            {
                key: 'initiator_car_make',
                value: 'Тойота',
                title: 'Марка личного автомобиля сотрудника',
                view_type: 'text',
                read_only: true,
            },
            {
                key: 'initiator_car_model',
                value: 'Rav4',
                title: 'Модель личного автомобиля сотрудника',
                view_type: 'text',
                read_only: true,
            },
            {
                key: 'fuel_cards_type',
                value: 'Пластиковая',
                title: 'Тип топливной карты',
                view_type: 'text',
                read_only: true,
            },
            {
                key: 'address',
                value: 'г Москва, р-н Текстильщики, ул 11-я Текстильщиков',
                title: 'Адрес доставки пластиковой топливной карты',
                view_type: 'text',
                read_only: true,
            },
            {
                key: 'fuel_company',
                value: 'ГазпромНефть',
                title: 'Наименование топливной компании',
                view_type: 'text',
                read_only: true,
            },
            { key: 'fuel_type', value: 'АИ-92', title: 'Тип топлива', view_type: 'text', read_only: true },
            {
                key: 'limit_bend',
                value: 100,
                title: 'Лимит по бэнду, литры',
                view_type: 'formReadOnlyText',
                read_only: false,
                type: 'number',
            },
            { key: 'requested_limit', value: 100, title: 'Запрошенный лимит, литры', view_type: 'number', read_only: true },
            {
                key: 'temporary_limit',
                value: 100,
                title: 'Согласованный лимит, литры',
                view_type: 'number',
                read_only: false,
            },
            {
                key: 'temporary_limit_start_date',
                value: '',
                title: 'Начало временного периода',
                view_type: 'date',
                read_only: false,
            },
            {
                key: 'temporary_limit_end_date',
                value: '',
                title: 'Окончание временного периода',
                view_type: 'date',
                read_only: false,
            },
            { key: 'extra_date_fields', title: '', value: null, read_only: false, view_type: 'extraFields' },
            { key: 'initiator_mvz', value: '13CT35U7', title: 'МВЗ сотрудника', view_type: 'text', read_only: false },
            { key: 'commentary', value: null, title: 'Комментарий инициатора', view_type: 'text', read_only: true },
        ],
        tabs: [
            {
                key: 'info',
                title: 'Данные по сотруднику',
                fields: [
                    'initiator_fullname',
                    'initiator_tn',
                    'initiator_phone_number',
                    'initiator_staff_name',
                    'initiator_org_unit_name',
                    'initiator_bend',
                    'initiator_car_make',
                    'initiator_car_model',
                ],
                actions: [],
                isSelected: true,
            },
            {
                key: 'info_sim',
                title: 'Данные по топливной карте',
                fields: [
                    'fuel_cards_type',
                    'address',
                    'fuel_company',
                    'fuel_type',
                    'limit_bend',
                    'requested_limit',
                    'temporary_limit',
                    'temporary_limit_start_date',
                    'temporary_limit_end_date',
                    'initiator_mvz',
                    'commentary',
                ],
                actions: ['done', 'specify', 'correct'],
                isSelected: false,
            },
        ],
        modals: [
            {
                title: 'Отклонение по согласованию выдачи топливной карты',
                description: 'Пожалуйста, укажите причину отклонения выдачи топливной карты.',
                action_id: 'correct',
            },
            {
                title: 'Уточнение по согласованию выдачи топливной карты',
                description: 'Пожалуйста, укажите причину уточнения по выдаче топливной карты.',
                action_id: 'specify',
            },
        ],
        application_id: '6b8d748a-4da5-4ba1-9629-68ce94f9135a',
    },
};
export const testClientInfo = {
    id: 13029,
    first_name: 'Maksim',
    last_name: 'Test',
    current_plan: null,
    current_price_per_week: null,
    duration: null,
    hubspot_id: '28299419100',
    space_ids: [544],
    comments: [],
    start_date: '2024-07-14',
    estimated_end_date: '2024-09-12',
    end_date: null,
    spaces: [
        {
            id: 544,
            user_id: 13029,
            user_name: 'Maksim Test',
            storage_id: 1,
            unit_id: 166,
            created_at: '2024-08-06T16:12:02.675442Z',
            items: [
                {
                    id: '6dd95ddd-e093-4bbb-97b1-a620945253e3',
                    key: 'smallBox',
                    title: 'small box',
                    amount: 2,
                    info_id: '42df8acc-aa32-40dd-882c-dcf3663cee03',
                },
                {
                    id: '56f5f07e-92c5-4784-9a48-b7ae9d7c21a8',
                    key: 'bag',
                    title: 'bag',
                    amount: 3,
                    info_id: '7b4b4523-3e76-4a0c-a630-255fac2889b8',
                },
                {
                    id: '2da5298e-91a7-4f1c-9ce8-340ecef34d0f',
                    key: 'tenFlowers',
                    title: 'ten flowers',
                    amount: 1,
                    info_id: '08e0295c-9dbb-447d-8775-bd94d538a0d1',
                },
                {
                    id: '8fc734ab-75d7-418e-9a16-84dbb2eeedb1',
                    key: 'largeBox',
                    title: 'large box',
                    amount: 1,
                    info_id: '384d073a-eb36-464a-a43c-406ec1805ee5',
                },
            ],
            comments: [
                {
                    id: '9dd8819c-4ac6-41a6-800a-55b24126bdb1',
                    content: 'Client was not polite, said that his flowers are very expensive.',
                    created_at: '2024-08-06T16:11:45.371390Z',
                },
            ],
            media: [
                {
                    id: '0330b92580334c3ca94d4f259070ac16-CleanShot_2024-08-05_at_17.38.37.png',
                    url: 'https://storage.googleapis.com/stackt-storage/0330b92580334c3ca94d4f259070ac16-CleanShot_2024-08-05_at_17.38.37.png',
                    content_type: 'image/png',
                    created_at: '2024-08-06T16:12:00.558732Z',
                    thumbnail: null,
                },
                {
                    id: '7186b6fe67634b5e90156b8162b12821-CleanShot_2024-08-06_at_17.51.35.mp4',
                    url: 'https://storage.googleapis.com/stackt-storage/7186b6fe67634b5e90156b8162b12821-CleanShot_2024-08-06_at_17.51.35.mp4',
                    content_type: 'video/mp4',
                    created_at: '2024-08-06T16:12:02.120724Z',
                    thumbnail: {
                        id: '7186b6fe67634b5e90156b8162b12821-CleanShot_2024-08-06_at_17.51.35.mp4___thumbnail.jpeg',
                        url: 'https://storage.googleapis.com/stackt-storage/7186b6fe67634b5e90156b8162b12821-CleanShot_2024-08-06_at_17.51.35.mp4___thumbnail.jpeg',
                        content_type: 'image/jpeg',
                    },
                },
            ],
            history: [
                {
                    id: 'f9523666-69b9-4be6-be65-7ec953819748',
                    created_at: '2024-08-06T16:12:03.104742Z',
                    date: '2024-08-06',
                    order_id: 16008001016,
                    type: 'CREATE',
                    changes: {
                        added: {
                            comments: [
                                {
                                    id: '9dd8819c-4ac6-41a6-800a-55b24126bdb1',
                                    content: 'Client was not polite, said that his flowers are very expensive.',
                                    created_at: '2024-08-06T16:11:45.371390Z',
                                },
                            ],
                            items: [
                                {
                                    id: '6dd95ddd-e093-4bbb-97b1-a620945253e3',
                                    key: 'smallBox',
                                    title: 'small box',
                                    amount: 2,
                                    info_id: '42df8acc-aa32-40dd-882c-dcf3663cee03',
                                },
                                {
                                    id: '56f5f07e-92c5-4784-9a48-b7ae9d7c21a8',
                                    key: 'bag',
                                    title: 'bag',
                                    amount: 3,
                                    info_id: '7b4b4523-3e76-4a0c-a630-255fac2889b8',
                                },
                                {
                                    id: '2da5298e-91a7-4f1c-9ce8-340ecef34d0f',
                                    key: 'tenFlowers',
                                    title: 'ten flowers',
                                    amount: 1,
                                    info_id: '08e0295c-9dbb-447d-8775-bd94d538a0d1',
                                },
                                {
                                    id: '8fc734ab-75d7-418e-9a16-84dbb2eeedb1',
                                    key: 'largeBox',
                                    title: 'large box',
                                    amount: 1,
                                    info_id: '384d073a-eb36-464a-a43c-406ec1805ee5',
                                },
                            ],
                            media: [
                                {
                                    id: '0330b92580334c3ca94d4f259070ac16-CleanShot_2024-08-05_at_17.38.37.png',
                                    url: 'https://storage.googleapis.com/stackt-storage/0330b92580334c3ca94d4f259070ac16-CleanShot_2024-08-05_at_17.38.37.png',
                                    content_type: 'image/png',
                                    created_at: '2024-08-06T16:12:00.558732Z',
                                    thumbnail: null,
                                },
                                {
                                    id: '7186b6fe67634b5e90156b8162b12821-CleanShot_2024-08-06_at_17.51.35.mp4',
                                    url: 'https://storage.googleapis.com/stackt-storage/7186b6fe67634b5e90156b8162b12821-CleanShot_2024-08-06_at_17.51.35.mp4',
                                    content_type: 'video/mp4',
                                    created_at: '2024-08-06T16:12:02.120724Z',
                                    thumbnail: {
                                        id: '7186b6fe67634b5e90156b8162b12821-CleanShot_2024-08-06_at_17.51.35.mp4___thumbnail.jpeg',
                                        url: 'https://storage.googleapis.com/stackt-storage/7186b6fe67634b5e90156b8162b12821-CleanShot_2024-08-06_at_17.51.35.mp4___thumbnail.jpeg',
                                        content_type: 'image/jpeg',
                                    },
                                },
                            ],
                        },
                        removed: null,
                        fields: [
                            {
                                field_id: 'unit_id',
                                old: null,
                                new: 166,
                            },
                        ],
                    },
                },
            ],
            hubspot_order_id: 16008001016,
        },
    ],
};

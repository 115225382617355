import React from 'react';
import { FormContainer, FormWrapper } from './Components';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { Button, FormInput } from '#Components/shared';
const LoginForm = ({ signIn, loggingIn, error }) => {
    const FormSchema = object().shape({
        email: string().required('Field is required'),
        password: string().required('Field is required'),
    });
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: FormSchema,
        onSubmit: async (values, actions) => {
            signIn({ username: values.email, password: values.password });
        },
    });
    return (React.createElement(FormWrapper, null,
        React.createElement(FormContainer, { onSubmit: formik.handleSubmit, id: "login_form_1" },
            React.createElement("h5", null, "Sign in to Stackt Storage"),
            React.createElement(FormInput, { name: "Work Email", onChange: formik.handleChange('email'), value: formik.values.email, type: "email", errorText: formik.errors.email, bottomMargin: 8 }),
            React.createElement(FormInput, { name: "Password", onChange: formik.handleChange('password'), value: formik.values.password, type: "password", errorText: error ? 'Incorrect email or password' : formik.errors.password, bottomMargin: 20 }),
            React.createElement(Button, { kind: "primary", loading: loggingIn, type: "submit", full: true }, "Log in"))));
};
export default LoginForm;

import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';
const Container = styled.div `
  margin: 0 auto;
  max-width: 358px;
  border-radius: 16px 16px 0px 0px;
  position: fixed;
  max-height: 90vh;
  left: 0;
  right: 0;
  bottom: ${props => props.bottomOffset};
  background: white;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: bottom 0.3s ease;
`;
const CloseBtn = styled.div `
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  font-size: 18px;
`;
const TopTouchRow = styled.div `
  position: absolute;
  top: 10px;
  left: calc(50% - 23px);
  width: 46px;
  height: 2px;
  border-radius: 5px;
  background-color: var(--main-gray-dark);
`;
const Content = styled.div `
  padding: 40px 20px;
`;
const SwipeContainer = ({ children, needCloseBtn, opened, onClose }) => {
    const [bottomOffset, setBottomOffset] = useState(0);
    const containerRef = useRef(null);
    const clientHeight = containerRef.current?.clientHeight || 0;
    useEffect(() => {
        setBottomOffset(opened ? 0 : -clientHeight || -1000);
    }, [opened]);
    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            if (eventData.event.target?.closest('#transfer_unit_list')) {
                return;
            }
            if (eventData.dir === 'Down') {
                const threshold = containerRef.current ? clientHeight * 0.3 : 0;
                if (Math.abs(eventData.deltaY) > threshold) {
                    onClose && onClose();
                }
                else {
                    setBottomOffset(0);
                }
            }
        },
        onSwiping: (eventData) => {
            if (eventData.event.target?.closest('#transfer_unit_list')) {
                return;
            }
            if (eventData.dir === 'Down') {
                setBottomOffset(-eventData.deltaY * 2);
            }
        },
        preventScrollOnSwipe: false,
        trackMouse: false,
    });
    const combinedRef = (node) => {
        containerRef.current = node;
        handlers.ref(node);
    };
    return (React.createElement(Container, { bottomOffset: !opened ? '-120%' : `${bottomOffset}px`, ref: combinedRef },
        React.createElement(Content, null,
            React.createElement(TopTouchRow, null),
            needCloseBtn && React.createElement(CloseBtn, { onClick: onClose }, "\u2716"),
            children)));
};
export default SwipeContainer;

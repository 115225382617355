export var ReturnStatus;
(function (ReturnStatus) {
    ReturnStatus["NEW_REQUEST"] = "NEW_REQUEST";
    ReturnStatus["PAID_ONLINE"] = "PAID_ONLINE";
    ReturnStatus["NO_PICKUP"] = "NO_PICKUP";
    ReturnStatus["CALL_BACK"] = "CALL_BACK";
    ReturnStatus["CALCULATED"] = "CALCULATED";
    ReturnStatus["PROPOSAL"] = "PROPOSAL";
    ReturnStatus["SEND_INVOICE"] = "SEND_INVOICE";
    ReturnStatus["PAYMENT_PENDING"] = "PAYMENT_PENDING";
    ReturnStatus["BOOKED"] = "BOOKED";
    ReturnStatus["CANCELLED_LEAD"] = "CANCELLED_LEAD";
    ReturnStatus["CONFIRMED"] = "CONFIRMED";
    ReturnStatus["EXECUTORS_APPOINTED"] = "EXECUTORS_APPOINTED";
    ReturnStatus["WORK_IN_PROGRESS"] = "WORK_IN_PROGRESS";
    ReturnStatus["MOVE_EXECUTED"] = "MOVE_EXECUTED";
    ReturnStatus["COMPLETED"] = "COMPLETED";
    ReturnStatus["CANCELLED_MOVE"] = "CANCELLED_MOVE";
})(ReturnStatus || (ReturnStatus = {}));
export var Services;
(function (Services) {
    Services["MOVING"] = "MOVING";
    Services["STORAGE_COLLECTION"] = "STORAGE_COLLECTION";
    Services["STORAGE_RETURN"] = "STORAGE_RETURN";
    Services["INTERNATIONAL_SHIPPING"] = "INTERNATIONAL_SHIPPING";
    Services["CLEANING"] = "CLEANING";
    Services["PACKING"] = "PACKING";
    Services["UNPACKING"] = "UNPACKING";
})(Services || (Services = {}));

import { BLock } from '#Components/UnitBlock/UnitBlock';
import { DesignFonts } from '../../../style/DesignFonts';
import styled from 'styled-components';
export const StorageBlock = styled(BLock) `
  justify-content: center;
  align-items: center;
`;
export const StorageBLockTitle = styled.h3 `
  ${DesignFonts.headlineH5}
`;
